<template>
  <header class="header"> 
    <div class="header-inner">
      <div class="row">
        <!--<div class="col">  
           <h1>{{ $route.meta.title }}</h1> 
        </div>-->
        <div class="col">
          <button   
            class="navbar-toggler" 
             type="button" 
             :class="{ active: isactive }" 
             @click="activelink"
            >
            <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M25.1875 11.875H3.8125" stroke="#9E9E9E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M25.1875 7.125H3.8125" stroke="#9E9E9E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M25.1875 16.625H3.8125" stroke="#9E9E9E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M25.1875 21.375H3.8125" stroke="#9E9E9E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg> 
          </button> 

          <div v-if="$can('view-company-balance')">

            <div class="gl-ballance" v-if="userType != 'super-admin' || !userType">
              <span class="label">Account Balance: </span>
              <span class="ballance"> 
                <div 
                    v-if="respnseLoader" 
                    class="response-loader" 
                    style="--n: 5"
                  >
                  <div class="response-loader-inner">
                    <div class="dot" style="--i: 0"></div>
                    <div class="dot" style="--i: 1"></div>
                    <div class="dot" style="--i: 2"></div>
                    <div class="dot" style="--i: 3"></div>
                    <div class="dot" style="--i: 4"></div>
                  </div>
                </div> 
                                
                {{ ($store.state.auth.user.gl_balance) ? $store.state.auth.user.gl_balance : "Rs. 0" }} </span>
            </div>
            
          </div>

          <div class="nav-holder">
            <ul class="topnav">
              <li class="topnav-item notifications-item d-none">
                <a
                  href="#"
                  class="topnav-link"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src="../assets/images/alert.svg" alt="" />
                  <span class="bullet"></span>
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                  <div class="drop-head">
                    <span class="drop-head-text"> Notifications </span>
                    <span class="drop-counter"> 123 </span>
                  </div>
                  <div class="dropdown-content">
                    <div class="vertical-scroll">
                      <a href="#" class="dropdown-item">
                        <div class="notification-icon">M</div>
                        <p class="notification-detail-text">
                          CPR Rejected
                          <span class="date-time">
                            Aug 15, 2017 at 5:40 pm
                          </span>
                          <span class="muted-text">
                            <b> Muhammad Aon </b> Rejected your CPR please
                            review again and resend.
                          </span>
                        </p>
                      </a>
                      <a href="#" class="dropdown-item">
                        <div class="notification-icon">M</div>
                        <p class="notification-detail-text">
                          CPR Approved
                          <span class="date-time">
                            Aug 15, 2017 at 5:40 pm
                          </span>
                          <span class="muted-text">
                            <b> Muhammad Umar Butt </b> Approved your CPR.
                          </span>
                        </p>
                      </a>
                      <a href="#" class="dropdown-item">
                        <div class="notification-icon">M</div>
                        <p class="notification-detail-text">
                          CPR Perocessed
                          <span class="date-time">
                            Aug 15, 2017 at 5:40 pm
                          </span>
                          <span class="muted-text">
                            <b> Hafiz Shuja Rahman </b>
                            processed your CPR.
                          </span>
                        </p>
                      </a>
                    </div>
                    <a href="#" class="dropdown-item view-all text-center">
                      View all
                    </a>
                  </div>
                </div>
              </li>
              <li class="topnav-item user-item">
                <a
                  href="#"
                  class="topnav-link"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <strong class="user-image">{{ initials }}</strong>
                  <div class="user-detail">
                    <span class="name"> {{ name }} </span>
                    <span class="designation"> {{ designation }} </span>
                  </div>
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                  <RouterLink :to="this.getUserId()" class="dropdown-item">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="12" cy="6" r="4" fill="#1C274C" />
                      <ellipse
                        opacity="0.5"
                        cx="12"
                        cy="17"
                        rx="7"
                        ry="4"
                        fill="#1C274C"
                      />
                    </svg>

                    My Account
                  </RouterLink>
                  <RouterLink to="/change-password" class="dropdown-item">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.5"
                        d="M18.9771 14.7904C21.6743 12.0932 21.6743 7.72013 18.9771 5.02291C16.2799 2.3257 11.9068 2.3257 9.20961 5.02291C7.41866 6.81385 6.8169 9.34366 7.40432 11.6311C7.49906 12 7.41492 12.399 7.14558 12.6684L3.43349 16.3804C3.11558 16.6984 2.95941 17.1435 3.00906 17.5904L3.24113 19.679C3.26587 19.9017 3.36566 20.1093 3.52408 20.2677L3.73229 20.4759C3.89072 20.6343 4.09834 20.7341 4.32101 20.7589L6.4096 20.9909C6.85645 21.0406 7.30164 20.8844 7.61956 20.5665L8.32958 19.8565L9.39026 18.7958L11.3319 16.8541C11.6013 16.5848 12 16.5009 12.3689 16.5957C14.6563 17.1831 17.1861 16.5813 18.9771 14.7904Z"
                        fill="#1C274C"
                      />
                      <path
                        d="M15.4142 8.58579C14.6332 7.80474 13.3668 7.80474 12.5858 8.58579C11.8047 9.36683 11.8047 10.6332 12.5858 11.4142C13.3668 12.1953 14.6332 12.1953 15.4142 11.4142C16.1953 10.6332 16.1953 9.36683 15.4142 8.58579Z"
                        fill="#1C274C"
                      />
                      <path
                        d="M6.58295 18.1294L8.3291 19.8565L9.38977 18.7958L7.63776 17.063C7.34326 16.7717 6.86839 16.7743 6.57711 17.0688C6.28584 17.3633 6.28845 17.8382 6.58295 18.1294Z"
                        fill="#1C274C"
                      />
                    </svg>
                    Change Password
                  </RouterLink>
                  <a
                    href="javascript:void(0);"
                    @click="LOGOUT()"
                    class="dropdown-item"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.5"
                        d="M8.7919 5.14693C9.17345 4.98572 9.35208 4.54573 9.19087 4.16417C9.02966 3.78262 8.58966 3.60399 8.2081 3.7652C4.70832 5.24388 2.25 8.70906 2.25 12.7502C2.25 18.1349 6.61522 22.5002 12 22.5002C17.3848 22.5002 21.75 18.1349 21.75 12.7502C21.75 8.70906 19.2917 5.24388 15.7919 3.7652C15.4103 3.60399 14.9703 3.78262 14.8091 4.16417C14.6479 4.54573 14.8265 4.98572 15.2081 5.14693C18.1722 6.39928 20.25 9.33294 20.25 12.7502C20.25 17.3065 16.5563 21.0002 12 21.0002C7.44365 21.0002 3.75 17.3065 3.75 12.7502C3.75 9.33294 5.82779 6.39928 8.7919 5.14693Z"
                        fill="#1C274C"
                      />
                      <path
                        d="M12.75 2.75C12.75 2.33579 12.4142 2 12 2C11.5858 2 11.25 2.33579 11.25 2.75V6.75C11.25 7.16421 11.5858 7.5 12 7.5C12.4142 7.5 12.75 7.16421 12.75 6.75V2.75Z"
                        fill="#1C274C"
                      />
                    </svg>

                    Logout
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapActions } from "vuex";
import { LOGOUT } from "@/store/actions";
import store from "@/store";
import Swal from "sweetalert2";
import { API,STORAGE } from '@/services';


export default {
  data() {
    return {
      respnseLoader: false,
      isactive: false,
      detail: null,
      initials: "",
      name: "",
      designation: "",
      userType : "",
      gl_balance: localStorage.getItem("gl_balance"),
    };
  },
  created() {
    let app = this;
    setTimeout(() => {
  
      app.detail = store.state.auth.user;
      app.name = app.detail?.data?.name;
      app.designation = app.detail?.data?.user_type?.slug;
      app.userType = app.detail?.data?.user_type?.slug;
      app.initials = app.detail?.data?.name?.charAt();


    }, 1000);

    app.getGlBalance();
    
  },
  methods: {
    ...mapActions([LOGOUT]),

    getUserId() {

     let user_id=JSON.parse(localStorage.getItem("user"))?.data?.id;
      return '/profile/'+user_id
    },

    getGlBalance(){
        let vm = this;
        vm.respnseLoader = true;
        API.endpoints.payments.getGlAccountBalance()
        .then((response)=>{
          let balance =  response?.data?.data?.balance;
          if(balance){
            localStorage.setItem("gl_balance", balance);
            vm.gl_balance = localStorage.getItem("gl_balance");
            this.$store.state.auth.user.gl_balance = vm.gl_balance;
          }
        })
        .catch((ex)=>{ 
          console.log(ex?.response?.data?.message);
          // Swal.fire("Failed",ex?.response?.data?.message,"error");
        }).finally(()=>{
          vm.respnseLoader = false;
          vm.loader=false
      })      
    },  
    
    

    
    activelink() {
      var body = document.body; 
      body.classList.toggle("active-sidebar"); 
    }
  },
};
</script>
