    import { createApp } from 'vue'
import App from './App.vue'

import * as bootstrap from 'bootstrap'
import router from './router'
import store from './store'
import acl from './acl';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { VueFinalModal } from 'vue-final-modal'
import VueApexCharts from "vue3-apexcharts"
import lodash from "lodash";
import '../src/assets/sass/app.scss';
import { Helpers } from '../src/services/helpers.service';


import Pagination from 'v-pagination-3';
    import CKEditor from '@ckeditor/ckeditor5-vue';

    const app = createApp(App)
app.component("VueFinalModal", VueFinalModal)
app.component("pagination", Pagination)
app.use(router)
app.use(store)
app.use(acl)
app.use(bootstrap)
app.use(Helpers)
app.use(lodash)
app.use(CKEditor)
library.add(fas, fab)
app.component('fa-icon', FontAwesomeIcon)
app.use(VueApexCharts)
app.mount('#app')
