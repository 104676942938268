<template>
    <div>
    <div class="pm-employee-view-main">
        <div v-if="respnseLoader" class="response-loader" style="--n: 5">
            <div class="response-loader-inner">
                <div class="dot" style="--i: 0"></div>
                <div class="dot" style="--i: 1"></div>
                <div class="dot" style="--i: 2"></div>
                <div class="dot" style="--i: 3"></div>
                <div class="dot" style="--i: 4"></div>
            </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="row mb-3">
                <div class="col align-right">
                    <button class="btn btn-primary active" @click="addEducationHistory()">Add Education History</button>
                </div>
            </div>
            <div class="employment-history-outer loop-wrapper" v-for="(value,key) in items" :key="key">
              <div class="pm-employee-detail-head clearfix"> 
                <span class="title"> Employee Education Details {{ key + 1 }}  </span>
                <span class="float-end" v-if="items.length" @click="updateEducationHistory(value)">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd" d="M3.25 22C3.25 21.5858 3.58579 21.25 4 21.25H20C20.4142 21.25 20.75 21.5858 20.75 22C20.75 22.4142 20.4142 22.75 20 22.75H4C3.58579 22.75 3.25 22.4142 3.25 22Z" fill="#1C274C"/>
                      <path opacity="0.5" d="M19.0807 7.37162C20.3095 6.14279 20.3095 4.15046 19.0807 2.92162C17.8519 1.69279 15.8595 1.69279 14.6307 2.92162L13.9209 3.63141C13.9306 3.66076 13.9407 3.69052 13.9512 3.72066C14.2113 4.47054 14.7022 5.45356 15.6256 6.37698C16.549 7.30039 17.532 7.79126 18.2819 8.05142C18.3119 8.06183 18.3415 8.07187 18.3708 8.08155L19.0807 7.37162Z" fill="#1C274C"/>
                      <path d="M13.9511 3.59961L13.9205 3.63017C13.9303 3.65952 13.9403 3.68928 13.9508 3.71942C14.211 4.4693 14.7018 5.45232 15.6252 6.37574C16.5487 7.29915 17.5317 7.79002 18.2816 8.05018C18.3113 8.0605 18.3407 8.07046 18.3696 8.08005L11.5198 14.9299C11.058 15.3917 10.827 15.6227 10.5724 15.8213C10.2721 16.0555 9.94711 16.2564 9.60326 16.4202C9.31177 16.5591 9.00196 16.6624 8.38235 16.869L5.11497 17.9581C4.81005 18.0597 4.47388 17.9804 4.24661 17.7531C4.01934 17.5258 3.93998 17.1897 4.04162 16.8847L5.13074 13.6173C5.33728 12.9977 5.44055 12.6879 5.57947 12.3964C5.74334 12.0526 5.94418 11.7276 6.17844 11.4273C6.37702 11.1727 6.60794 10.9418 7.06971 10.48L13.9511 3.59961Z" fill="#1C274C"/>
                  </svg>

                </span>
              </div>
              <div class="pm-employee-detail-box mb-3">
                <div class="row mb-2">
                  <div class="col-md-4"> <strong class="title"> Institution Name: </strong> </div>
                  <div class="col-md-8"> <p> {{ value?.institution_name  ?? 'N/A' }} </p> </div>
                </div>
                <div class="row mb-2">
                  <div class="col-md-4"> <strong class="title"> Field of Study: </strong> </div>
                  <div class="col-md-8"> <p> {{ value?.field_of_study  ?? 'N/A' }} </p> </div>
                </div>
                <div class="row mb-2">
                  <div class="col-md-4"> <strong class="title"> Obtained Marks: </strong> </div>
                  <div class="col-md-8"> <p> {{ value?.obtained_marks  ?? 'N/A' }} </p> </div>
                </div>
                <div class="row mb-2">
                  <div class="col-md-4"> <strong class="title"> Total Marks: </strong> </div>
                  <div class="col-md-8"> <p> {{ value?.total_marks  ?? 'N/A' }} </p> </div>
                </div>
                <div class="row mb-2">
                  <div class="col-md-4"> <strong class="title"> Start Date: </strong> </div>
                  <div class="col-md-8"> <p> {{ value?.start_date  ?? 'N/A' }} </p> </div>
                </div>
                <div class="row mb-2">
                  <div class="col-md-4"> <strong class="title"> End Date: </strong> </div>
                  <div class="col-md-8"> <p> {{ value?.end_date  ?? 'N/A' }} </p> </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <updateEducationHistory ref="UpdateEducationHistory" />
    <addEducationHistory ref="AddEducationHistory" />
   
</template>
  
<script>

import { API } from '@/services';
import updateEducationHistory from '../modals/UpdateEducationHistory'
import addEducationHistory from '../modals/AddEducationHistory'

export default {

  name: 'EducationHistory',
  components:{
    updateEducationHistory,
    addEducationHistory
  },
  data() {
    return {
      loader : false,
      employeeId: this.$parent.employeeId,
      items: {},
      respnseLoader: false
    }
  },
  methods : {
    educationHistory(){
        let vm = this;
        this.respnseLoader=true
        API.endpoints.user.userEducationHistory(vm.employeeId)
        .then((data)=>{
          console.log('data education')
          console.log(data)
          vm.items = data?.data?.data ?? null
        })
        .catch((ex)=>{ 
          reject(ex)
        }).finally(()=>{
          vm.respnseLoader=false
      })
    },

    updateEducationHistory(value){
      this.$refs.UpdateEducationHistory.modal.show()
      this.$refs.UpdateEducationHistory.form = value
      this.$refs.UpdateEducationHistory.employeeID = value.id
    },
    addEducationHistory(){
      this.$refs.AddEducationHistory.form = {
        institution_name: '',
        field_of_study: '',
        obtained_marks: '',
        total_marks: '',
        start_date: '',
        end_date: ''
      }
      this.$refs.AddEducationHistory.employeeID = this.$parent.employeeId
      this.$refs.AddEducationHistory.modal.show()
    },
  },
  created() {
    // this.educationHistory();
  }
}
</script>