<template>
  
<div  
  class="modal fade" 
  id="editModalUser" 
  ref="AddJobDetails" 
  aria-hidden="true" 
  aria-labelledby="AddJobDetailsToggleLabel" 
  tabindex="-1"
> 
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row mb-4">
          <div class="col">
            <h2> Add Job Details </h2>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label
                  class="label block-label"
                  for="position"
              >
                Position
                <em>*</em>
              </label>
              <input
                  type="text"
                  class="form-control"
                  id="position"
                  required
                  v-model="form.position"
                  placeholder="Position"
              />
              <span
                  class="text-danger"
                  v-if="validations.position"
              >
                {{validations.position[0]}}
              </span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label
                  class="label block-label"
                  for="employee_code"
              >
                Employee Code
                <em>*</em>
              </label>
              <input
                  type="text"
                  class="form-control"
                  required
                  id="employee_code"
                  v-model="form.employee_code"
                  placeholder="Employee Code"
              />
              <span
                  class="text-danger"
                  v-if="validations.employee_code"
              >
                {{validations.employee_code[0]}}
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label
                  class="label block-label"
                  for="report_to"
              >
                Report TO
                <em>*</em>
              </label>
              <input
                  type="text"
                  name="report_to"
                  id="report_to"
                  class="form-control"
                  required
                  v-model="form.report_to"
                  placeholder="Report To"
              />
              <span
                  class="text-danger"
                  v-if="validations.report_to"
              >
                {{validations.report_to[0]}}
              </span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label
                  class="label block-label"
                  for="office_address"
              >
                Office Address
                <em>*</em>
              </label>
              <input
                  type="text"
                  name="office_address"
                  class="form-control"
                  required
                  v-model="form.office_address"
                  id="office_address"
                  placeholder="Office Address"
              />
              <span
                  class="text-danger"
                  v-if="validations.office_address"
              >
                {{validations.office_address[0]}}
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
          <div class="form-group">
            <label
                class="label block-label"
                for="hiring_date"
            >
              Hiring Date
              <em>*</em>
            </label>
            <input
                  type="date"
                  name="hiring_date"
                  class="form-control"
                  required
                  v-model="form.hiring_date"
                  id="hiring_date"
                  placeholder="Hiring Date"
              />
              <span class="text-danger" v-if="validations?.hiring_date">{{validations?.hiring_date[0]}}</span>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label
                class="label block-label"
                for="gross_salary"
            >
              Gross Salary
              <em>*</em>
            </label>
            <input
                  type="number"
                  name="gross_salary"
                  class="form-control"
                  required
                  v-model="form.gross_salary"
                  id="gross_salary"
                  placeholder="Gross Salary"
              />
              <span class="text-danger" v-if="validations?.gross_salary">{{validations?.gross_salary[0]}}</span>    
          </div>
        </div>
      </div>
      <div class="row">

        <div class="col-md-6">
          <div class="form-group">
            <label
                class="label block-label"
                for="net_salary"
            >
              Net Salary
              <em>*</em>
            </label>
            <input
                  type="number"
                  name="net_salary"
                  class="form-control"
                  required
                  v-model="form.net_salary"
                  id="net_salary"
                  placeholder="Net Salary"
              />
              <span class="text-danger" v-if="validations?.net_salary">{{validations?.net_salary[0]}}</span>    
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label
                class="label block-label"
                for="provident_fund"
            >
              Provident Fund
              <em>*</em>
            </label>
            <input
                  type="number"
                  name="provident_fund"
                  class="form-control"
                  required
                  v-model="form.provident_fund"
                  id="provident_fund"
                  placeholder="Provident Fund"
              />
              <span class="text-danger" v-if="validations?.provident_fund">{{validations?.provident_fund[0]}}</span>    
          </div>
        </div>
      </div>
      <div class="row">

        <div class="col-md-6">
          <div class="form-group">
            <label
                class="label block-label"
                for="other_deduction"
            >
              Other Deduction
              <em>*</em>
            </label>
            <input
                  type="number"
                  name="other_deduction"
                  class="form-control"
                  required
                  v-model="form.other_deduction"
                  id="other_deduction"
                  placeholder="Other Deduction"
              />
              <span class="text-danger" v-if="validations?.other_deduction">{{validations?.other_deduction[0]}}</span>    
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label
                class="label block-label"
                for="income_tax"
            >
              Income Tax
              <em>*</em>
            </label>
            <input
                  type="number"
                  name="income_tax"
                  class="form-control"
                  required
                  v-model="form.income_tax"
                  id="income_tax"
                  placeholder="Income Tax"
              />
              <span class="text-danger" v-if="validations?.income_tax">{{validations?.income_tax[0]}}</span>    
          </div>
        </div>
      </div>
      <div class="row">

        <div class="col-md-6">
          <div class="form-group">
            <label
                class="label block-label"
                for="eobi"
            >
              EOBI
              <em>*</em>
            </label>
            <input
                  type="number"
                  name="eobi"
                  class="form-control"
                  required
                  v-model="form.eobi"
                  id="eobi"
                  placeholder="Other Deduction"
              />
              <span class="text-danger" v-if="validations?.eobi">{{validations?.eobi[0]}}</span>    
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label
                class="label block-label"
                for="employee_type"
            >
              Employee Type
              <em>*</em>
            </label>
            <select 
                v-model="form.employee_type" 
                class="form-control form-select">
                <option value="" selected> -- Select Employee Type -- </option>
                <option  value="permanent"> Permanent </option>
                <option class="contract"> Contract </option>
            </select>
            <!-- <input
                  type="text"
                  name="employee_type"
                  class="form-control"
                  required
                  v-model="form.employee_type"
                  id="employee_type"
                  placeholder="Employee Type"
              /> -->
              <span class="text-danger" v-if="validations?.employee_type">{{validations?.employee_type[0]}}</span>    
          </div>
        </div>
      </div>
        
        <div class="row form-action-btns align-right">
          <div class="col">
            <button
                data-bs-dismiss="modal" aria-label="Close"
                type="button"
                class="btn btn-primary"
            >
              Close
            </button>
            <button
                type="submit"
                @click="addEmployeeJobDetail"
                class="btn btn-primary ms-2 active"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>

import {API,STORAGE} from "@/services";
import Swal from "sweetalert2";
import { Modal } from 'bootstrap';

export default {
  data(){
    return {
      modal: null,
      validations:{},
      form: {
        user_id: '',
        position: '',
        employee_code: '',
        report_to: '',
        office_address: '',
        hiring_date: '',
        gross_salary: '',
        net_salary: '',
        provident_fund: '',
        other_deduction: '',
        income_tax: '',
        eobi: '',
        employee_type: ''
      },
      employeeID : ''
    }
  },
  created(){},
  methods: {
    addEmployeeJobDetail(){
      let vm = this;
      API.endpoints.user.addEmployeeJobDetails(vm.form, vm.employeeID)
      .then((response) => {
          vm.$emit("update");
          vm.modal.hide(); 
      })
      .catch((ex)=>{
        if(ex.response.status==422){
            vm.validations=(ex.response.data.errors);
        }
      })
    },
    beforeOpen(){
      this.validations = {};
    }
  },
  watch:{
    validations:function(){
    }
  },
  mounted(){
      this.modal = new Modal(this.$refs.AddJobDetails)
      this.modal._element.addEventListener('show.bs.modal', () => {
        this.$emit('modal-show')
        this.beforeOpen()
      })
    }
}
</script>
    