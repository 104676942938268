<template>
<div class="content-pages-inner">
  <div class="page-top mb-1 mb-3 clearfix">
      <h2>My Account</h2>
  </div> 
  <div class="pages-generic">
    <div class="form-holder">
      <div class="mb-3">
        <label class="label block-label" for="name">Name</label>
        <input id="name" name="name" type="text" v-model="form.name" class="form-control" autocomplete="off" placeholder="Enter full name" required />
        <span class="text-danger" v-if="validations.name">{{validations.name[0]}}</span>
      </div>
      <div class="mb-3" v-if="form.email">
        <label class="label block-label" for="email">Email</label>
        <input id="email" name="email" type="email" v-model="form.email" class="form-control" autocomplete="off" placeholder="Enter email" required disabled />
        <span class="text-danger" v-if="validations.email">{{validations.email[0]}}</span>
      </div>
      <div class="mb-3" v-if="form.company">
        <label class="label block-label" for="email">Company</label>
        <input id="company" name="company" type="company" v-model="form.company" class="form-control" autocomplete="off" placeholder="Enter company" required disabled />
        <span class="text-danger" v-if="validations.company">{{validations.company[0]}}</span>
      </div>
      <div class="mb-3" v-if="form.department">
        <label class="label block-label" for="email">Department</label>
        <input id="department" name="department" type="department" v-model="form.department" class="form-control" autocomplete="off" placeholder="Enter department" required disabled />
        <span class="text-danger" v-if="validations.department">{{validations.department[0]}}</span>
      </div>
      <div class="mb-3" v-if="form.designation">
        <label class="label block-label" for="email">Designation</label>
        <input id="designation" name="designation" type="designation" v-model="form.designation" class="form-control" autocomplete="off" placeholder="Enter designation" required disabled />
        <span class="text-danger" v-if="validations.designation">{{validations.designation[0]}}</span>
      </div>
      <div class="mb-3">
        <h5 class="mb-3" for="email">Account Verification Status</h5>
        <img 
          title="Verified" 
          src="../../../assets/images/verified.png" 
          style="width: 5%;" 
          v-if="$store?.state?.auth?.user?.data?.is_password_verified == 1"
        >
        <img 
          title="Not Verified" 
          src="../../../assets/images/not_verified.png" 
          style="width: 5%;" 
          v-else
        >
      </div>
      <div class="mb-0">
        <button :disabled='isDisabled' @click="updateProfile()" class="btn btn-primary active">Save</button>
      </div>
    </div>
  </div>
</div>
</template>
  
  <script>

import { API } from '@/services';
import Swal from 'sweetalert2'
import { mapMutations } from 'vuex';
import { STORAGE } from "./../../../services/store.service";
import { SET_USER } from './../../../store/mutations'



export default {

  name: 'PersonelAccountDetail',

  data() {
    return {
      loader : false,
      validations : {},
      form : {
        name : this?.$store?.state?.auth?.user?.data?.name,
        email : this?.$store?.state?.auth?.user?.data?.email,
        company : this?.$store?.state?.auth?.user?.data?.company_detail?.name,
        department : this?.$store?.state?.auth?.user?.data?.department?.name,
        designation : this?.$store?.state?.auth?.user?.data?.designation?.name
      }
    }
  },
  methods : {
    ...mapMutations([
      SET_USER
    ]),
    updateProfile: function () {
      let vm = this;
      vm.isDisabled = true;

      API.endpoints.user.updateProfile(this.form)
      .then((data) => {
        STORAGE.USER.set(data.data)
        this.SET_USER(data.data)
        Swal.fire('Success!', "Profile Updated Successfully!", 'success');
        vm.isDisabled = false;
      })
      .catch((ex) => {
        Swal.fire('Error!', "Something went wrong!", 'error');
        vm.isDisabled = false;
      });
    }
  }
}
</script>


