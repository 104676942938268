export const STORAGE = {
  TOKEN: {
    key: 'token',
    set(data){
      localStorage.setItem(this.key, JSON.stringify(data))  
    },
    get(){
      return JSON.parse(localStorage.getItem(this.key))
    },
    remove(){
      localStorage.removeItem(this.key)
    },
  },
  USER: {
    key: 'user',
    set(data){
      localStorage.setItem(this.key, JSON.stringify(data))
    },
    setUserData(data){
      localStorage.setItem(this.key + ".data", JSON.stringify(data))
    },
    get(){
      return JSON.parse(localStorage.getItem(this.key))
    },
    remove(){
      localStorage.removeItem(this.key)
    },
  },
}