import router from "../router"; 
import store from "../store";

import { createAcl, defineAclRules } from 'vue-simple-acl';
import { GET_USER } from "@/store/getters";
import { STORAGE } from "@/services";
import { computed } from 'vue';

const user = computed(() => store.state.auth.user);

const rules = () => defineAclRules((setRule) => {
  
  setRule('dashboard',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'dashboard'))
  setRule('modules',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'modules'))
  setRule('companies',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'companies'))
  setRule('add-module',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'add-module'))
  setRule('delete-module',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'delete-module'))
  setRule('update-module',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'update-module'))
  setRule('services',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'services'))
  setRule('add-service',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'add-service'))
  setRule('delete-service',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'delete-service'))

  setRule('update-service',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'update-service'))
  setRule('permissions',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'permissions'))

  setRule('add-permission',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'add-permission'))
  setRule('delete-permission',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'delete-permission'))
  setRule('update-permission',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'update-permission'))

  setRule('banks',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'banks'))
  setRule('add-bank',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'add-bank'))
  setRule('delete-bank',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'delete-bank'))
  setRule('update-bank',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'update-bank'))
  setRule('add-company',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'add-company'))
  setRule('delete-company',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'delete-company'))
  setRule('update-company',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'update-company'))
  setRule('branches',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'branches'))
  setRule('add-branch',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'add-branch'))
  setRule('delete-branch',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'delete-branch'))
  setRule('update-branch',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'update-branch'))
  setRule('view-configuration',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'view-configuration'))
  setRule('update-configuration',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'update-configuration'))

  setRule('departments',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'departments'))
  setRule('add-department',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'add-department'))
  setRule('delete-department',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'delete-department'))
  setRule('update-department',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'update-department'))
  setRule('designations',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'designations'))
  setRule('add-designation',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'add-designation'))

  setRule('delete-designation',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'delete-designation'))
  setRule('update-designation',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'update-designation'))

  setRule('users',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'users'))
  setRule('add-user',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'add-user'))
  setRule('delete-user',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'delete-user'))
  setRule('update-user',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'update-user'))
  setRule('view-user-permissions',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'view-user-permissions'))
  setRule('update-user-permissions',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'update-user-permissions'))
  setRule('view-company-balance',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'view-company-balance'))

  setRule('cpr',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'cpr'))
  setRule('cpr-dashboard',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'cpr-dashboard'))
  setRule('view-initiated-cpr',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'view-initiated-cpr'))
  setRule('view-lending-cpr',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'view-lending-cpr'))
  setRule('view-instant-cpr',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'view-instant-cpr'))
  setRule('generate-cpr',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'generate-cpr'))
  setRule('view-cpr-details',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'view-cpr-details'))
  setRule('edit-cpr',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'edit-cpr'))
  setRule('download-cpr',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'download-cpr'))
  setRule('change-original-cpr',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'change-original-cpr'))
  setRule('pending-to-under_verification',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'pending-to-under_verification'))
  setRule('under_verification-to-approved',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'under_verification-to-approved'))
  setRule('approved-to-disbursed',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'approved-to-disbursed'))
  setRule('initiated-to-pending',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'initiated-to-pending'))
  setRule('reject-cpr',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'reject-cpr'))
  setRule('rejected-to-pending',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'rejected-to-pending'))
  setRule('download-overall-report',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'download-overall-report'))
  setRule('download-instant-report',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'download-instant-report'))
  setRule('download-institute-report',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'download-institute-report'))
  setRule('upload-cpr-batch',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'upload-cpr-batch'))
  setRule('change-cpr-amount',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'change-cpr-amount'))
  setRule('change-cpr-number',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'change-cpr-number'))
  setRule('change-passbook-number',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'change-passbook-number'))
  setRule('view-institute-cpr',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'view-institute-cpr'))
  setRule('update-bulk-cpr-status',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'update-bulk-cpr-status'))
  setRule('update-bulk-repaid-status',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'update-bulk-repaid-status'))


  // setRule('approved-lending',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'approved-lending'))
  // setRule('reject-lending',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'reject-lending'))
  // setRule('approve-financials',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'approve-financials'))
  // setRule('reject-financials',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'reject-financials'))
  // setRule('loan-disbursed',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'loan-disbursed'))
  // setRule('reject-loan-disbursment',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'reject-loan-disbursment'))
  // setRule('lending-approval-request',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'lending-approval-request'))
  
  setRule('bulk-registration',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'bulk-registration'))
  setRule('upload-file',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'upload-file'))
  setRule('view-uploaded-files',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'view-uploaded-files'))
  setRule('view-uploaded-file-detail',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'view-uploaded-file-detail'))
  setRule('edit-uploaded-file-detail',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'edit-uploaded-file-detail'))
  setRule('screening-col',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'screening-col'))
  setRule('nadra-verysis-col',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'nadra-verysis-col'))
  setRule('send-for-review-col',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'send-for-review-col'))
  setRule('wallet-registration-col',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'wallet-registration-col'))
  setRule('save-for-registration-request',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'save-for-registration-request'))
  setRule('send-wallet-registration-request',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'send-wallet-registration-request'))
  setRule('manual-approve-nadra',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'manual-approve-nadra'))
  setRule('view-uploaded-file-detail-frm',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'view-uploaded-file-detail-frm'))
  setRule('screening-onhold-manual-approval',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'screening-onhold-manual-approval'))
  setRule('run-screening-scheduler',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'run-screening-scheduler'))
  setRule('run-nadra-verisys-scheduler',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'run-nadra-verisys-scheduler'))

  setRule('wallet-registration',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'wallet-registration'))
  setRule('guest-users',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'guest-users'))
  setRule('view-guest-user-detail',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'view-guest-user-detail'))
  setRule('pending-customers',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'pending-customers'))
  setRule('view-pending-customers-detail',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'view-pending-customers-detail'))
  setRule('approved-customers',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'approved-customers'))
  setRule('view-approved-customers-detail',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'view-approved-customers-detail'))
  
  setRule('processed-customers',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'processed-customers'))
  setRule('view-processed-customers-detail',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'view-processed-customers-detail'))
  
  setRule('rejected-wallet-customers',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'rejected-wallet-customers'))
  setRule('view-rejected-wallet-customers-detail',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'view-rejected-wallet-customers-detail'))
  setRule('onhold-verification-customers',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'onhold-verification-customers'))
  setRule('view-onhold-verification-customers-detail',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'view-onhold-verification-customers-detail'))
  setRule('screening-verification-customers',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'screening-verification-customers'))
  setRule('view-screening-verification-customers-detail',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'view-screening-verification-customers-detail'))
  setRule('approve-wallet-registration',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'approve-wallet-registration'))
  setRule('reject-wallet-registration',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'reject-wallet-registration'))
  setRule('rejected-after-screening',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'rejected-after-screening'))
  setRule('manual-approved-by-frm',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'manual-approved-by-frm'))

  setRule('dms',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'dms'))
  setRule('view-documents',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'view-documents'))
  setRule('upload-document',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'upload-document'))
  setRule('edit-document',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'edit-document'))
  setRule('approve-document',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'approve-document'))
  setRule('reject-document',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'reject-document'))
  setRule('assign-collaborators',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'assign-collaborators'))
  setRule('share-feeback',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'share-feeback'))
  setRule('view-feedback-changes',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'view-feedback-changes'))
  setRule('approve-feedbacks',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'approve-feedbacks'))
  setRule('return-feedbacks',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'return-feedbacks'))
  setRule('sign-off-document',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'sign-off-document'))

  setRule('vendor',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'vendor'))
  setRule('vendor-bulk-registration',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'vendor-bulk-registration'))
  setRule('add-vendor',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'add-vendor'))
  setRule('view-vendor',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'view-vendor'))
  setRule('edit-vendor',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'edit-vendor'))
  setRule('delete-vendor',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'delete-vendor'))


  setRule('farmer',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'farmer'))
  setRule('farmer-bulk-registration',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'farmer-bulk-registration'))
  setRule('add-farmer',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'add-farmer'))
  setRule('view-farmer',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'view-farmer'))
  setRule('edit-farmer',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'edit-farmer'))
  setRule('delete-farmer',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'delete-farmer'))

  setRule('salary-disbursement',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'salary-disbursement'))
  setRule('salary-batch-upload',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'salary-batch-upload'))
  setRule('salary-auto-generate',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'salary-auto-generate'))
  setRule('view-salary-batch-detail',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'view-salary-batch-detail'))
  setRule('salary-account-inquiry',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'salary-account-inquiry'))
  setRule('salary-payment-confirmation',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'salary-payment-confirmation'))
  setRule('salary-payment-success',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'salary-payment-success'))
  setRule('salary-payment-reject',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'salary-payment-reject'))
  setRule('download-salary-payment-batch',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'download-salary-payment-batch'))
  setRule('download-salary-payment-batch-inquiry',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'download-salary-payment-batch-inquiry'))

  setRule('vendor-payment',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'vendor-payment'))
  setRule('vendor-batch-upload',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'vendor-batch-upload'))
  setRule('vendor-auto-generate',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'vendor-auto-generate'))
  setRule('view-vendor-batch-detail',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'view-vendor-batch-detail'))
  setRule('vendor-account-inquiry',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'vendor-account-inquiry'))
  setRule('vendor-payment-confirmation',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'vendor-payment-confirmation'))
  setRule('vendor-payment-success',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'vendor-payment-success'))
  setRule('vendor-payment-reject',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'vendor-payment-reject'))
  setRule('download-vendor-payment-batch',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'download-vendor-payment-batch'))
  setRule('download-vendor-payment-batch-inquiry',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'download-vendor-payment-batch-inquiry'))

  setRule('farmer-payment',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'farmer-payment'))
  setRule('farmer-batch-upload',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'farmer-batch-upload'))
  setRule('farmer-auto-generate',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'farmer-auto-generate'))
  setRule('view-farmer-batch-detail',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'view-farmer-batch-detail'))
  setRule('farmer-account-inquiry',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'farmer-account-inquiry'))
  setRule('farmer-payment-confirmation',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'farmer-payment-confirmation'))
  setRule('farmer-payment-success',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'farmer-payment-success'))
  setRule('farmer-payment-reject',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'farmer-payment-reject'))
  setRule('download-farmer-payment-batch',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'download-farmer-payment-batch'))
  setRule('download-farmer-payment-batch-inquiry',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'download-farmer-payment-batch-inquiry'))

  setRule('topup-payment',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'topup-payment'))
  setRule('topup-batch-upload',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'topup-batch-upload'))
  setRule('topup-single-payment',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'topup-single-payment'))
  setRule('view-topup-batch-detail',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'view-topup-batch-detail'))
  setRule('topup-account-inquiry',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'topup-account-inquiry'))
  setRule('topup-payment-confirmation',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'topup-payment-confirmation'))
  setRule('topup-payment-success',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'topup-payment-success'))
  setRule('topup-payment-reject',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'topup-payment-reject'))
  setRule('download-topup-payment-batch',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'download-topup-payment-batch'))
  setRule('download-topup-payment-batch-inquiry',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'download-topup-payment-batch-inquiry'))

  setRule('bill-payment',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'bill-payment'))
  setRule('bill-batch-upload',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'bill-batch-upload'))
  setRule('bill-single-payment',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'bill-single-payment'))
  setRule('view-bill-batch-detail',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'view-bill-batch-detail'))
  setRule('bill-account-inquiry',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'bill-account-inquiry'))
  setRule('bill-payment-confirmation',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'bill-payment-confirmation'))
  setRule('bill-payment-success',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'bill-payment-success'))
  setRule('bill-payment-reject',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'bill-payment-reject'))
  setRule('download-bill-payment-batch',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'download-bill-payment-batch'))
  setRule('download-bill-payment-batch-inquiry',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'download-bill-payment-batch-inquiry'))

  setRule('ibft-payment',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'ibft-payment'))
  setRule('ibft-batch-upload',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'ibft-batch-upload'))
  setRule('ibft-single-payment',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'ibft-single-payment'))
  setRule('view-ibft-batch-detail',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'view-ibft-batch-detail'))
  setRule('ibft-account-inquiry',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'ibft-account-inquiry'))
  setRule('ibft-payment-confirmation',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'ibft-payment-confirmation'))
  setRule('ibft-payment-success',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'ibft-payment-success'))
  setRule('ibft-payment-reject',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'ibft-payment-reject'))
  setRule('download-ibft-payment-batch',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'download-ibft-payment-batch'))
  setRule('download-ibft-payment-batch-inquiry',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'download-ibft-payment-batch-inquiry'))

  setRule('w2w-credit-payment',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'w2w-credit-payment'))
  setRule('w2w-credit-batch-upload',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'w2w-credit-batch-upload'))
  setRule('w2w-credit-single-payment',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'w2w-credit-single-payment'))
  setRule('view-w2w-credit-batch-detail',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'view-w2w-credit-batch-detail'))
  setRule('w2w-credit-account-inquiry',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'w2w-credit-account-inquiry'))
  setRule('w2w-credit-payment-confirmation',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'w2w-credit-payment-confirmation'))
  setRule('w2w-credit-payment-success',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'w2w-credit-payment-success'))
  setRule('w2w-credit-payment-reject',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'w2w-credit-payment-reject'))
  setRule('download-w2w-credit-payment-batch',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'download-w2w-credit-payment-batch'))
  setRule('download-w2w-credit-payment-batch-inquiry',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'download-w2w-credit-payment-batch-inquiry'))

  setRule('w2w-debit-payment',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'w2w-debit-payment'))
  setRule('w2w-debit-batch-upload',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'w2w-debit-batch-upload'))
  setRule('w2w-debit-single-payment',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'w2w-debit-single-payment'))
  setRule('view-w2w-debit-batch-detail',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'view-w2w-debit-batch-detail'))
  setRule('w2w-debit-account-inquiry',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'w2w-debit-account-inquiry'))
  setRule('w2w-debit-payment-confirmation',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'w2w-debit-payment-confirmation'))
  setRule('w2w-debit-payment-success',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'w2w-debit-payment-success'))
  setRule('w2w-debit-payment-reject',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'w2w-debit-payment-reject'))
  setRule('download-w2w-debit-payment-batch',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'download-w2w-debit-payment-batch'))
  setRule('download-w2w-debit-payment-batch-inquiry',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'download-w2w-debit-payment-batch-inquiry'))

  setRule('external-users',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'external-users'))
  setRule('add-external-users',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'add-external-users'))
  setRule('update-external-users',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'update-external-users'))
  setRule('delete-external-users',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'delete-external-users'))
  setRule('view-external-users-permissions',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'view-external-users-permissions'))
  setRule('update-external-users-permissions',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'update-external-users-permissions'))

  setRule('reimbursements',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'reimbursements'))
  setRule('view-reimbursement',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'view-reimbursement'))
  setRule('view-generate-reimbursement-request',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'view-users-reimbursement-request'))
  setRule('generate-reimbursement-request',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'generate-reimbursement-request'))
  setRule('edit-reimbursement-request',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'edit-reimbursement-request'))
  setRule('pending-to-under_review-reimbursement',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'pending-to-under_review-reimbursement'))
  setRule('disbursed-reimbursement-request',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'disbursed-reimbursement-request'))
  setRule('reject-reimbursement-request',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'reject-reimbursement-request'))

  setRule('cpr-disbursement',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'cpr-disbursement'))
  setRule('cpr-disbursement-batch-upload',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'cpr-disbursement-batch-upload'))
  setRule('cpr-disbursement-single-payment',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'cpr-disbursement-single-payment'))
  setRule('view-cpr-disbursement-batch-detail',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'view-cpr-disbursement-batch-detail'))
  setRule('cpr-disbursement-account-inquiry',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'cpr-disbursement-account-inquiry'))
  setRule('cpr-disbursement-confirmation',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'cpr-disbursement-confirmation'))
  setRule('cpr-disbursement-success',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'cpr-disbursement-success'))
  setRule('cpr-disbursement-reject',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'cpr-disbursement-reject'))
  setRule('download-cpr-disbursement-batch',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'download-cpr-disbursement-batch'))
  setRule('download-cpr-disbursement-batch-inquiry',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'download-cpr-disbursement-batch-inquiry'))


  setRule('view-blocked-users-list',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'view-blocked-users-list'))
  setRule('single-unblock-user',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'single-unblock-user'))
  setRule('bulk-unblock-users',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'bulk-unblock-users'))
  setRule('view-unblock-users-activity',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'view-unblock-users-activity'))
  
  setRule('company-gl-account-list',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'company-gl-account-list'))
  setRule('create-company-gl-account',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'create-company-gl-account'))
  setRule('delete-company-gl-account',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'delete-company-gl-account'))
  setRule('update-company-gl-account',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'update-company-gl-account'))
  setRule('update-company-gl-account-status',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'update-company-gl-account-status'))
  setRule('update-company-gl-account-default-status',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'update-company-gl-account-default-status'))
  setRule('financial_institute',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'financial_institute'))


  setRule('user-bulk-registration-list',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'user-bulk-registration-list'))
  setRule('batch-upload-user-bulk-registration',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'batch-upload-user-bulk-registration'))
  setRule('user-bulk-registration-batch-inquiry',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'user-bulk-registration-batch-inquiry'))

});

const simpleAcl = createAcl({
  user,
  rules,
  router, 
});

export default simpleAcl;