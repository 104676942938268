import Vue from 'vue'
import { createWebHistory, createRouter }  from "vue-router";
import { STORAGE } from './services';
import store from './store';

import layout from '@/views/app/Main.vue'

import Auth from '@/views/auth/Login.vue'
import Login from '@/views/auth/Login.vue'
import Profile from '@/views/app/user/Profile.vue'



export const route_names = {
  home: 'home',
  auth: 'auth',
  login: 'login',
  superadminauth: 'superadmin-auth',
  dashboard: 'dashboard',
  forgot_password: 'forgot-password',
  company: 'company',
  company_gli: 'company-gli',
  department: 'department',
  designation: 'designation',
  branch: 'branch',
  bank: 'bank',
  guest: 'guest',
  pending: 'pending',
  suspended: 'suspended',
  activated: 'activated',
  on_hold: 'on-hold',
  closed: 'closed',
  blocked: 'blocked',
  screening_verification: 'screening-verification',
  approved: 'approved',
  processed: 'processed',
  rejected: 'rejected',
  user: 'user',
  main: 'main',
  module: 'module',
  bulk_registration: 'bulk_registration',
  bulk_registration_rejected: 'bulk_registration_rejected',
  View_bulk_registration: 'View_bulk_registration',
  View_bulk_registration_rejected: 'view_bulk_registration_rejected',
  permissions: 'permissions',
  services: 'services',
  services: 'cpr',
  rejected_after_screening: 'rejected-after-screening',
  manual_approved_by_frm: 'manual-approved-by-frm',
  reset_password: 'reset-password',
  change_password: 'change-password',
  set_password: 'set-password',
  profile: 'profile',
  branch_processed: 'branch_processed',
  department_processed: 'department_processed',
  designation_processed: 'designation_processed',
  user_processed: 'user_processed',
  workspace: 'workspace',
  document: 'document',
  documentDetail: 'document-detail',
  documentDetailChanges: 'document-detail-changes',
  employees: 'employees',
  employeeDetail: 'employee-detail',
  farmerDetail: 'farmer-detail',
  vendorDetail: 'vendor-detail',
  vendors: 'vendors',
  farmers: 'farmers',
  payrollBatches: 'payroll-batches',
  payrollBatchDetails: 'payroll-batch-detail',
  payrollAutoGenerate: 'payroll-auto-generate',
  vendorPaymentBatchList: 'vendor-payment-batch',
  vendorPaymentBatchDetails: 'vendor-payment-batch-detail',
  // vendorPaymentAutoGenerate: 'vendor-payment-auto-generate',
  farmerPaymentBatchList: 'farmer-payment-batch',
  farmerPaymentBatchDetails: 'farmer-payment-batch-detail',
  farmerPaymentAutoGenerate: 'farmer-payment-auto-generate',
  billPayments: 'bill-payments',
  topupPayments: 'topup-payments',
  telcos: 'telcos',
  billerDetail: 'biller-detail',
  ibftPaymentsBatches: 'ibft-payment-batches',
  ibftPaymentsBatchesDetail: 'ibft-payment-batch-detail',
  w2wCreditPaymentBatches: 'w2w-credit-payment-batches',
  w2wCreditPaymentsBatchDetail: 'w2w-credit-payment-batch-detail',
  w2wDebitPaymentBatches: 'w2w-debit-payment-batches',
  w2wDebitPaymentsBatchDetail: 'w2w-debit-payment-batch-detail',
  initiatedCpr: 'cpr-initiated',
  cprDisbursement: 'cpr-disbursement',
  cprDisbursementBatchDetail: 'cpr-disbursement-batch-detail',
  blockedUser: 'blocked-user',
  user_bulk_registration_batch_detail: 'user_bulk_registration_batch_detail',
  user_bulk_registration_batch: 'user_bulk_registration_batch',

}

const router = createRouter({
  history: createWebHistory(),
  routes: [
      {
        name: route_names.auth,
        path: '/',
        component: Auth,
        redirect: '/login',
        meta: {
          not_require_auth: true,
          title : 'Login',
        },
        children: [
          {
            path: '/login',
            name: route_names.login,
            component: Login
          }
        ]
      },
      {
        name: route_names.superadminauth,
        path: '/',
        redirect: '/superadmin-auth',
        meta: {
          not_require_auth: true,
          title : 'superadmin-auth',
        },
        children: [
          {
            path: '/superadmin-auth',
            name: route_names.superadminauth,
            component: () => import('@/views/auth/SuperAdminAuth.vue')
          }
        ]
      },
      {
        name: route_names.set_password,
        path: '/set-password',
        redirect: '/set-password',
        meta: {
          require_auth: true,
        },
        children: [
          {
            path: '/set-password',
            name: route_names.set_password,
            component: () => import('@/views/auth/SetPassword.vue')
          }
        ]
      },
      {
        name: route_names.forgot_password,
        path: '/forgot-password',
        redirect: '/forgot-password',
        meta: {
          not_require_auth: false,
        },
        children: [
          {
            path: '/forgot-password',
            name: route_names.forgot_password,
            component: () => import('@/views/auth/ForgotPassword.vue')
          }
        ]
      },
      {
        name: route_names.reset_password,
        path: '/reset-password/:token/:email',
        redirect: '/reset-password/:token/:email',
        meta: {
          not_require_auth: false,
        },
        children: [
          {
            path: '/reset-password/:token/:email',
            name: route_names.reset_password,
            component: () => import('@/views/auth/ResetPassword.vue')
          }
        ]
      },
      {
        name: route_names.change_password,
        path: '/change-password',
        component: layout,
        redirect: '/change-password',
        meta: {
          // can: 'dashboard',
          onDeniedRoute: 'login',
          require_auth: true,
        },
        children: [
          {
            name: route_names.change_password,
            path: '/change-password',
            component: () => import('@/views/auth/ChangePassword.vue')
          }
        ],
      },
      {
        name: route_names.profile,
        path: '/profile/:id',
        redirect: '/profile/:id',
        component: layout,
        meta: {
          require_auth: true,
        },
        children: [
          {
            path: '/profile/:id',
            name: route_names.profile,
            component: () => import('@/views/app/user/Profile.vue')
          }
        ]
      },
      {
        name: route_names.dashboard,
        path: '/dashboard',
        component: layout,
        redirect: '/dashboard',
        meta: {
          // can: 'dashboard',
          // onDeniedRoute: 'login',
          require_auth: true,
          title : 'Dashboard',
        },
        children: [
          {
            name: route_names.dashboard,
            path: '/dashboard',
            component: () => import('@/views/app/dashboard/Dashboard')
          }
        ],
      },
      {
        name: route_names.bulk_registration,
        path: '/bulk-registration',
        component: layout,
        redirect: '/bulk-registration',
        meta: {
          require_auth: true,
          title : 'Bulk Registration',
        },
        children: [
            {
              name: route_names.bulk_registration,
              path: '/bulk-registration',
              component: () => import('@/views/app/bulk-registration/BulkRegistration')
            }
          ],
        },
      {
        name: route_names.home,
        path: '/',
        component: layout,
        redirect: '/home',
        meta: {
          require_auth: true,
          title : 'Home',
        },
        children: [
          {
            name: route_names.home,
            path: '/home',
            component: () => import('@/views/app/Home')
          }
        ],
      },
      {
        name: route_names.user,
        path: '/user',
        component: layout,
        redirect: '/user',
        meta: {
          can: 'users',
          onDeniedRoute: 'login',
          require_auth: true,
          title : 'User',
        },
        children: [
          {
            name: route_names.user,
            path: '/user',
            component: () => import('@/views/app/user/User')
          }
        ],
      },
      {
        name: route_names.ExternalUser,
        path: '/external-users',
        component: layout,
        redirect: '/external-users',
        meta: { 
          can: 'external-users',
          onDeniedRoute: 'login', 
        },
        children: [
          {
            name: route_names.ExternalUser,
            path: '/external-users',
            component: () => import('@/views/app/external-users/ExternalUser')
          }
        ],
      },
    {
      name: route_names.blockedUser,
      path: '/blocked-users',
      component: layout,
      redirect: '/blocked-users',
      meta: {
        can: 'view-blocked-users-list',
        onDeniedRoute: 'login',
      },
      children: [
        {
          name: route_names.blockedUser,
          path: '/blocked-users',
          component: () => import('@/views/app/blocked-users/BlockedUser')
        }
      ],
    },
    {
      name: route_names.user_bulk_registration_batch,
      path: '/user-bulk-registration-batch',
      component: layout,
      redirect: '/user-bulk-registration-batch',
      meta: {
         can: 'user-bulk-registration-list',
         onDeniedRoute: 'login',
      },
      children: [
        {
          name: route_names.user_bulk_registration,
          path: '/user-bulk-registration-batch',
          component: () => import('@/views/app/user-bulk-registration-batch/Index')
        }
      ],
    },

    {
      name: route_names.user_bulk_registration_batch_detail,
      path: '/user-bulk-registration-batch-detail/:id',
      component: layout,
      redirect: '/user-bulk-registration-batch-detail/:id',
      meta: {
        // can: 'view-cpr-disbursement-batch-detail',
       // onDeniedRoute: 'login',
       // require_auth: true,
        title : 'User Bulk Register Detail',
      },
      children: [
        {
          name: route_names.user_bulk_registration_batch_detail,
          path: '/user-bulk-registration-batch-detail/:id',
          component: () => import('@/views/app/user-bulk-registration-batch/batchDetail.vue')
        }
      ],
    }, 
      {
        name: route_names.OpdReimbursement,
        path: '/opd-reimbursement',
        component: layout,
        redirect: '/opd-reimbursement',
        meta: { 
          onDeniedRoute: 'login', 
        },
        children: [
          {
            name: route_names.OpdReimbursement,
            path: '/opd-reimbursement',
            component: () => import('@/views/app/reimbursement/Reimbursement')
          }
        ],
      },
      {
      name: route_names.cprDisbursement,
      path: '/cpr-disbursement',
      component: layout,
      redirect: '/cpr-disbursement',
      meta: {
        can: 'cpr-disbursement',
        onDeniedRoute: 'login',
      },
      children: [
        {
          name: route_names.cprDisbursement,
          path: '/cpr-disbursement',
          component: () => import('@/views/app/cpr-disbursement/AllBatches')
        }
      ],
    },
    {
      name: route_names.cprDisbursementBatchDetail,
      path: '/cpr-disbursement-batch-detail/:id',
      component: layout,
      redirect: '/cpr-disbursement-batch-detail/:id',
      meta: {
        can: 'view-cpr-disbursement-batch-detail',
        onDeniedRoute: 'login',
        require_auth: true,
        title : 'CPR Disbursement Detail',
      },
      children: [
        {
          name: route_names.cprDisbursementBatchDetail,
          path: '/cpr-disbursement-batch-detail/:id',
          component: () => import('@/views/app/cpr-disbursement/BatchDetail.vue')
        }
      ],
    }, 
      {
        name: route_names.user_processed,
        path: '/user/processed',
        component: layout,
        redirect: '/user/processed',
        meta: {
          can: 'users',
          onDeniedRoute: 'login',
          require_auth: true,
          title : 'User',
        },
        children: [
          {
            name: route_names.user_processed,
            path: '/user/processed',
            component: () => import('@/views/app/user/UserProcessed')
          }
        ],
      },
      {
        name: route_names.company,
        path: '/company',
        component: layout,
        redirect: '/Company',
        meta: {
          can: 'companies',
          onDeniedRoute: 'login',
          require_auth: true,
          title: 'Company'
        },
        children: [
          {
            name: route_names.company,
            path: '/company',
            component: () => import('@/views/app/company/Company')
          }
        ],
      }, 
      {
        name: route_names.company_GL,
        path: '/company-gl',
        component: layout,
        redirect: '/company-gl',
        meta: { 
          onDeniedRoute: 'login',
          require_auth: true,
          title: 'company-gl'
        },
        children: [
          {
            name: route_names.company_GL,
            path: '/company-gl',
            component: () => import('@/views/app/company-gl/company-gl')
          }
        ],
      }, 
      {
        name: route_names.branch,
        path: '/branch',
        component: layout,
        redirect: '/branch',
        meta: {
          can: 'branches',
          onDeniedRoute: 'login',
          require_auth: true,
          title : 'Branch',
        },
        children: [
          {
            name: route_names.branch,
            path: '/branch',
            component: () => import('@/views/app/branch/Branch')
          }
        ],
      }, 
      {
        name: route_names.branchn,
        path: '/branchn',
        component: layout,
        redirect: '/branchn',
        meta: {
          can: 'branches',
          onDeniedRoute: 'login',
          require_auth: true,
          title : 'Branch',
        },
        children: [
          {
            name: route_names.branchn,
            path: '/branchn',
            component: () => import('@/views/app/branchn/Branchn')
          }
        ],
      },
      {
        name: route_names.branch_processed,
        path: '/branch/processed',
        component: layout,
        redirect: '/branch/processed',
        meta: {
          can: 'branches',
          onDeniedRoute: 'login',
          require_auth: true,
          title : 'Processed',
        },
        children: [
          {
            name: route_names.branch_processed,
            path: '/branch/processed',
            component: () => import('@/views/app/branch/BranchProcessed')
          }
        ],
      },
      {
        name: route_names.department,
        path: '/department',
        component: layout,
        redirect: '/department',
        meta: {
          can: 'departments',
          onDeniedRoute: 'login',
          require_auth: true,
          title : 'Department',
        },
        children: [
          {
            name: route_names.department,
            path: '/department',
            component: () => import('@/views/app/department/Department')
          }
        ],
      },
      {
        name: route_names.department_processed,
        path: '/department/processed',
        component: layout,
        redirect: '/department/processed',
        meta: {
          can: 'departments',
          onDeniedRoute: 'login',
          require_auth: true,
          title : 'Processed',
        },
        children: [
          {
            name: route_names.department_processed,
            path: '/department/processed',
            component: () => import('@/views/app/department/DepartmentProcessed')
          }
        ],
      },
      {
        name: route_names.designation,
        path: '/designation',
        component: layout,
        redirect: '/designation',
        meta: {
          can: 'designations',
          onDeniedRoute: 'login',
          require_auth: true,
          title : 'Designation',
        },
        children: [
          {
            name: route_names.designation,
            path: '/designation',
            component: () => import('@/views/app/designation/Designation')
          }
        ],
      },
      {
        name: route_names.designation_processed,
        path: '/designation/processed',
        component: layout,
        redirect: '/designation/processed',
        meta: {
          can: 'designations',
          onDeniedRoute: 'login',
          require_auth: true,
          title : 'Processed',
        },
        children: [
          {
            name: route_names.designation_processed,
            path: '/designation/processed',
            component: () => import('@/views/app/designation/DesignationProcessed')
          }
        ],
      },
      {
        name: route_names.services,
        path: '/services',
        component: layout,
        redirect: '/services',
        meta: {
          can: 'services',
          onDeniedRoute: 'login',
          require_auth: true,
          title : 'Services',
        },
        children: [
          {
            name: route_names.services,
            path: '/services',
            component: () => import('@/views/app/services/Service')
          }
        ],
      },
      {
        name: route_names.bank,
        path: '/bank',
        component: layout,
        redirect: '/bank',
        meta: {
          can: 'banks',
          onDeniedRoute: 'login',
          require_auth: true,
          title : 'Bank',
        },
        children: [
          {
            name: route_names.bank,
            path: '/bank',
            component: () => import('@/views/app/banks/Bank')
          }
        ],
      },
      {
        name: route_names.module,
        path: '/module',
        component: layout,
        redirect: '/module',
        meta: {
          can: 'modules',
          onDeniedRoute: 'login',
          require_auth: true,
          title : 'Module',
        },
        children: [
          {
            name: route_names.module,
            path: '/module',
            component: () => import('@/views/app/modules/Module')
          }
        ],
      },
      {
        name: route_names.bulk_registration,
        path: '/bulk-registration',
        component: layout,
        redirect: '/bulk-registration',
        meta: {
          require_auth: true,
          can: 'bulk-registration',
          onDeniedRoute: 'login',
          title : 'Bulk Registration',
        },
        children: [
          {
            name: route_names.bulk_registration,
            path: '/bulk-registration',
            component: () => import('@/views/app/bulk-registration/BulkRegistration')
          }
        ],
      },          
      {
        name: 'ViewBulkRegistration',
        path: '/ViewBulkRegistration/:id',
        component: layout,
        redirect: '/ViewBulkRegistration/:id',
        meta: {
          require_auth: true,
          can: 'view-uploaded-file-detail',
          onDeniedRoute: 'login',
          title : 'View Bulk Registration',
        },
        children: [
          {
            name: route_names.View_bulk_registration,
            path: '/ViewBulkRegistration/:id',
            component: () => import('@/views/app/bulk-registration/ViewBulkRegistration')
          }
        ],
      },
      {
        name: 'ViewBulkRegistrationRejected',
        path: '/ViewBulkRegistrationRejected/:id',
        component: layout,
        redirect: '/ViewBulkRegistrationRejected/:id',
        meta: {
          require_auth: true,
          can: 'view-uploaded-file-detail',
          onDeniedRoute: 'login',
        },
        children: [
          {
            name: route_names.View_bulk_registration_rejected,
            path: '/ViewBulkRegistrationRejected/:id',
            component: () => import('@/views/app/bulk-registration/ViewBulkRegistrationRejected')
          }
        ],
      },

      {
        name: route_names.bulk_registration_rejected,
        path: '/bulk-registration/rejected',
        component: layout,
        redirect: '/bulk-registration/rejected',
        meta: {
          require_auth: true,
          can: 'bulk-registration',
          onDeniedRoute: 'login',
        },
        children: [
          {
            name: route_names.bulk_registration_rejected,
            path: '/bulk-registration/rejected',
            component: () => import('@/views/app/bulk-registration/BulkRegistrationRejected')
          }
        ],
      },       
      
      {
        name: route_names.permissions,
        path: '/permissions',
        component: layout,
        redirect: '/permissions',
        meta: {
          can: 'permissions',
          onDeniedRoute: 'login',
          require_auth: true,
          title : 'Permissions',
        },
        children: [
          {
            name: route_names.permissions,
            path: '/permissions',
            component: () => import('@/views/app/permissions/Permission')
          }
        ],
      },
      {
        name: route_names.cpr,
        path: '/cpr',
        component: layout,
        redirect: '/cpr',
        meta: {
          can: 'cpr',
          onDeniedRoute: 'login',
          require_auth: true,
          title : 'CPR Digitization'
        },
        children: [
          {
            name: route_names.cpr,
            path: '/cpr',
            component: () => import('@/views/app/cpr/Cpr')
          }
        ],
      },

      {
        name: route_names.cprDashboard,
        path: '/cprDashboard',
        component: layout,
        redirect: '/cprDashboard',
        meta: {
          can: 'cpr-dashboard',
          onDeniedRoute: 'login',
          require_auth: true,
          title : 'CPR Digitization'
        },
        children: [
          {
            name: route_names.cprDashboard,
            path: '/cprDashboard',
            component: () => import('@/views/app/cprDigitization/cprDashboard')
          }
        ],
      },

      {
        name: route_names.initiatedCpr,
        path: '/initiated-cpr',
        component: layout,
        redirect: '/initiated-cpr',
        meta: {
          can: 'view-initiated-cpr',
          onDeniedRoute: 'login',
          require_auth: true,
          title : 'Initiated CPRs'
        },
        children: [
          {
            name: route_names.initiatedCpr,
            path: '/initiated-cpr',
            component: () => import('@/views/app/lending-cpr/initiatedCpr')
          }
        ],
      }, 
      {
        name: route_names.lendingCpr,
        path: '/lendingCpr',
        component: layout,
        redirect: '/lendingCpr',
        meta: {
          can: 'view-lending-cpr',
          onDeniedRoute: 'login',
          require_auth: true,
          title : 'CPR Digitization'
        },
        children: [
          {
            name: route_names.lendingCpr,
            path: '/lendingCpr',
            component: () => import('@/views/app/lending-cpr/lendingCpr')
          }
        ],
      }, 
      {
        name: route_names.InstantCpr,
        path: '/InstantCpr',
        component: layout,
        redirect: '/InstantCpr',
        meta: {
          can: 'view-instant-cpr',
          onDeniedRoute: 'login',
          require_auth: true,
          title : 'CPR Digitization'
        },
        children: [
          {
            name: route_names.InstantCpr,
            path: '/InstantCpr',
            component: () => import('@/views/app/instant-cpr/InstantCpr')
          }
        ],
      }, 
      {
        name: 'unauthorized',
        path: '/unauthorized',
        redirect: '/unauthorized',
        meta: {
          require_auth: false,
        },
        children: [
          {
            name: 'unauthorized',
            path: '/unauthorized',
            component: () => import('@/views/auth/Unauthorized')
          }
        ],
      },
      {
        name: route_names.guest,
        path: '/guest',
        component: layout,
        redirect: '/guest',
        meta: {
          title : 'Guests',
          onDeniedRoute: 'login',
          require_auth: true,
        },
        children: [
          {
            name: route_names.guest,
            path: '/guest',
            component: () => import('@/views/app/wallet-registration/Guests')
          }
        ],
      },
      {
        name: route_names.pending,
        path: '/pending',
        component: layout,
        redirect: '/pending',
        meta: {
          title : 'Pending',
          onDeniedRoute: 'login',
          require_auth: true,
        },
        children: [
          {
            name: route_names.pending,
            path: '/pending',
            component: () => import('@/views/app/wallet-registration/Pending')
          }
        ],
      },
      {
        name: route_names.suspended,
        path: '/suspended',
        component: layout,
        redirect: '/suspended',
        meta: {
          title : 'Suspended',
          onDeniedRoute: 'login',
          require_auth: true,
        },
        children: [
          {
            name: route_names.suspended,
            path: '/suspended',
            component: () => import('@/views/app/wallet-registration/Suspended')
          }
        ],
      },
      {
        name: route_names.activated,
        path: '/activated',
        component: layout,
        redirect: '/activated',
        meta: {
          title : 'Activated',
          onDeniedRoute: 'login',
          require_auth: true,
        },
        children: [
          {
            name: route_names.activated,
            path: '/activated',
            component: () => import('@/views/app/wallet-registration/Activated')
          }
        ],
      },
      {
        name: route_names.closed,
        path: '/closed',
        component: layout,
        redirect: '/closed',
        meta: {
          title : 'Closed',
          onDeniedRoute: 'login',
          require_auth: true,
        },
        children: [
          {
            name: route_names.closed,
            path: '/closed',
            component: () => import('@/views/app/wallet-registration/Closed')
          }
        ],
      },
      {
        name: route_names.approved,
        path: '/approved',
        component: layout,
        redirect: '/approved',
        meta: {

          onDeniedRoute: 'login',
          require_auth: true,
        },
        children: [
          {
            name: route_names.approved,
            path: '/approved',
            component: () => import('@/views/app/wallet-registration/Approved')
          }
        ],
      },
      {
        name: route_names.processed,
        path: '/processed',
        component: layout,
        redirect: '/processed',
        meta: {
          onDeniedRoute: 'login',
          require_auth: true,
        },
        children: [
          {
            name: route_names.processed,
            path: '/processed',
            component: () => import('@/views/app/wallet-registration/Processed')
          }
        ],
      },
      {
        name: route_names.screening_verification,
        path: '/screening-verification',
        component: layout,
        redirect: '/screening-verification',
        meta: {

          onDeniedRoute: 'login',
          require_auth: true,
        },
        children: [
          {
            name: route_names.screening_verification,
            path: '/screening-verification',
            component: () => import('@/views/app/wallet-registration/ScreeningVerification')
          }
        ],
      },
      {
        name: route_names.blocked,
        path: '/blocked',
        component: layout,
        redirect: '/blocked',
        meta: {

          onDeniedRoute: 'login',
          require_auth: true,
        },
        children: [
          {
            name: route_names.blocked,
            path: '/blocked',
            component: () => import('@/views/app/wallet-registration/Blocked')
          }
        ],
      },
      {
        name: route_names.on_hold,
        path: '/blocked',
        component: layout,
        redirect: '/on-holder',
        meta: { 
          onDeniedRoute: 'login',
          require_auth: true,
        },
        children: [
          {
            name: route_names.on_hold,
            path: '/on-hold',
            component: () => import('@/views/app/wallet-registration/OnHold')
          }
        ],
      },
      {
        name: route_names.rejected,
        path: '/rejected',
        component: layout,
        redirect: '/rejected',
        meta: {
          onDeniedRoute: 'login',
          require_auth: true,
        },
        children: [
          {
            name: route_names.rejected,
            path: '/rejected',
            component: () => import('@/views/app/wallet-registration/Rejected')
          }
        ],
      },
      {
        name: route_names.rejected_after_screening,
        path: '/rejected-after-screening',
        component: layout,
        redirect: '/rejected-after-screening',
        meta: {
          onDeniedRoute: 'login',
          require_auth: true,
        },
        children: [
          {
            name: route_names.rejected_after_screening,
            path: '/rejected-after-screening',
            component: () => import('@/views/app/wallet-registration/RejectedAfterScreening')
          }
        ],
      },
      {
        name: route_names.manual_approved_by_frm,
        path: '/manual-approved-by-frm',
        component: layout,
        redirect: '/manual-approved-by-frm',
        meta: {
          onDeniedRoute: 'login',
          require_auth: true,
        },
        children: [
          {
            name: route_names.manual_approved_by_frm,
            path: '/manual-approved-by-frm',
            component: () => import('@/views/app/wallet-registration/ManualApprovedByFrm')
          }
        ],
      },
      {
        name: route_names.workspace,
        path: '/workspace',
        component: layout,
        redirect: '/workspace',
        meta: {
          onDeniedRoute: 'login',
          require_auth: true,
        },
        children: [
          {
            name: route_names.workspace,
            path: '/workspace',
            component: () => import('@/views/app/workspace/Workspace')
          }
        ],
      },
      {
        name: route_names.document,
        path: '/document',
        component: layout,
        redirect: '/document',
        meta: {
          onDeniedRoute: 'login',
          require_auth: true,
        },
        children: [
          {
            name: route_names.document,
            path: '/document',
            component: () => import('@/views/app/documents/Documents')
          }
        ],
      },
      {
        name: route_names.documentDetail,
        path: '/document-detail/:id',
        component: layout,
        redirect: '/document-detail/:id',
        meta: {
          onDeniedRoute: 'login',
          require_auth: true,
        },
        children: [
          {
            name: route_names.documentDetail,
            path: '/document-detail/:id',
            component: () => import('@/views/app/document-detail/DocumentDetail')
          }
        ],
      },

      // New Design Route 
      
      {
        name: route_names.newDesign,
        path: '/new-design',
        component: layout,
        redirect: '/new-design',
        meta: { 
          onDeniedRoute: 'login',
          require_auth: true,
          title: 'Company'
        },
        children: [
          {
            name: route_names.newDesign,
            path: '/new-design',
            component: () => import('@/views/app/new-design/NewDesign')
          }
        ],
      },

      // New Design Route

      {
        name: route_names.documentDetailChanges,
        path: '/document-detail-changes/:id',
        component: layout,
        redirect: '/document-detail-changes/:id',
        meta: {
          onDeniedRoute: 'login',
          require_auth: true,
        },
        children: [
          {
            name: route_names.documentDetailChanges,
            path: '/document-detail-changes/:id',
            component: () => import('@/views/app/document-detail-changes/DocumentDetail')
          }
        ],
      },
      {
        name: route_names.employees,
        path: '/employees',
        component: layout,
        redirect: '/employees',
        meta: {
          onDeniedRoute: 'login',
          require_auth: true,
        },
        children: [
          {
            name: route_names.employees,
            path: '/employees',
            component: () => import('@/views/app/employees/Employees')
          }
        ],
      },
      {
        name: route_names.farmerDetail,
        path: '/farmer-detail/:id',
        component: layout,
        redirect: '/farmer-detail',
        meta: {
          onDeniedRoute: 'login',
          require_auth: true,
        },
        children: [
          {
            name: route_names.farmerDetail,
            path: '/farmer-detail/:id',
            component: () => import('@/views/app/farmers/FarmerDetail.vue')
          }
        ],
      },
      {
        name: route_names.vendorDetail,
        path: '/vendor-detail/:id',
        component: layout,
        redirect: '/vendor-detail',
        meta: {
          onDeniedRoute: 'login',
          require_auth: true,
        },
        children: [
          {
            name: route_names.vendorDetail,
            path: '/vendor-detail/:id',
            component: () => import('@/views/app/vendors/VendorDetail.vue')
          }
        ],
      },
      {
        name: route_names.employeeDetail,
        path: '/employee-detail/:id',
        component: layout,
        redirect: '/employee-detail',
        meta: {
          onDeniedRoute: 'login',
          require_auth: true,
        },
        children: [
          {
            name: route_names.employeeDetail,
            path: '/employee-detail/:id',
            component: () => import('@/views/app/employee-detail/EmployeeDetail')
          }
        ],
      },
      {
        name: route_names.vendors,
        path: '/vendors',
        component: layout,
        redirect: '/vendors',
        meta: {
          onDeniedRoute: 'login',
          require_auth: true,
        },
        children: [
          {
            name: route_names.vendors,
            path: '/vendors',
            component: () => import('@/views/app/vendors/Vendors')
          }
        ],
      },
      {
        name: route_names.farmers,
        path: '/farmers',
        component: layout,
        redirect: '/farmers',
        meta: {
          onDeniedRoute: 'login',
          require_auth: true,
        },
        children: [
          {
            name: route_names.farmers,
            path: '/farmers',
            component: () => import('@/views/app/farmers/Farmers')
          }
        ],
      },
      {
        name: route_names.payrollBatches,
        path: '/payroll-batches',
        component: layout,
        redirect: '/payroll-batches',
        meta: {
          onDeniedRoute: 'login',
          require_auth: true,
        },
        children: [
          {
            name: route_names.payrollBatches,
            path: '/payroll-batches',
            component: () => import('@/views/app/salary-disbursment/PayrollBatches')
          }
        ],
      },
      {
        name: route_names.payrollDashboard,
        path: '/payroll-dashboard',
        component: layout,
        redirect: '/payroll-dashboard',
        meta: {
          onDeniedRoute: 'login',
          require_auth: true,
        },
        children: [
          {
            name: route_names.payrollDashboard,
            path: '/payroll-dashboard',
            component: () => import('@/views/app/payment-dashboard/PayrollDashboard')
          }
        ],
      },
      {
        name: route_names.payrollBatchDetails,
        path: '/payroll-batch-detail/:id',
        component: layout,
        redirect: '/payroll-batch-detail/:id',
        meta: {
          onDeniedRoute: 'login',
          require_auth: true,
        },
        children: [
          {
            name: route_names.payrollBatchDetails,
            path: '/payroll-batch-detail/:id',
            component: () => import('@/views/app/salary-disbursment/PayrollBatchDetail')
          }
        ],
      },
      {
        name: route_names.payrollAutoGenerate,
        path: '/payroll-auto-generate',
        component: layout,
        redirect: '/payroll-auto-generate',
        meta: {
          onDeniedRoute: 'login',
          require_auth: true,
        },
        children: [
          {
            name: route_names.payrollAutoGenerate,
            path: '/payroll-auto-generate',
            component: () => import('@/views/app/salary-disbursment/AutoGenerate')
          }
        ],
      },
      {
        name: route_names.vendorPaymentBatchList,
        path: '/vendor-payment-batch',
        component: layout,
        redirect: '/vendor-payment-batch',
        meta: {
          onDeniedRoute: 'login',
          require_auth: true,
        },
        children: [
          {
            name: route_names.vendorPaymentBatchList,
            path: '/vendor-payment-batch',
            component: () => import('@/views/app/vendor-payment/AllBatches')
          }
        ],
      },
      {
        name: route_names.vendorPaymentBatchDetails,
        path: '/vendor-payment-batch-detail/:id',
        component: layout,
        redirect: '/vendor-payment-batch-detail/:id',
        meta: {
          onDeniedRoute: 'login',
          require_auth: true,
        },
        children: [
          {
            name: route_names.vendorPaymentBatchDetails,
            path: '/vendor-payment-batch-detail/:id',
            component: () => import('@/views/app/vendor-payment/BatchDetail')
          }
        ],
      },
      {
        name: route_names.vendorPaymentAutoGenerate,
        path: '/vendor-payment-auto-generate',
        component: layout,
        redirect: '/vendor-payment-auto-generate',
        meta: {
          onDeniedRoute: 'login',
          require_auth: true,
        },
        children: [
          {
            name: route_names.vendorPaymentAutoGenerate,
            path: '/vendor-payment-auto-generate',
            component: () => import('@/views/app/vendor-payment/AutoGenerate')
          }
        ],
      },
    
      {
        name: route_names.farmerPaymentBatchList,
        path: '/farmer-payment-batch',
        component: layout,
        redirect: '/farmer-payment-batch',
        meta: {
          onDeniedRoute: 'login',
          require_auth: true,
        },
        children: [
          {
            name: route_names.farmerPaymentBatchList,
            path: '/farmer-payment-batch',
            component: () => import('@/views/app/farmer-payment/AllBatches')
          }
        ],
      },
      {
        name: route_names.farmerPaymentBatchDetails,
        path: '/farmer-payment-batch-detail/:id',
        component: layout,
        redirect: '/farmer-payment-batch-detail/:id',
        meta: {
          onDeniedRoute: 'login',
          require_auth: true,
        },
        children: [
          {
            name: route_names.farmerPaymentBatchDetails,
            path: '/farmer-payment-batch-detail/:id',
            component: () => import('@/views/app/farmer-payment/BatchDetail')
          }
        ],
      },
      {
        name: route_names.farmerPaymentAutoGenerate,
        path: '/farmer-payment-auto-generate',
        component: layout,
        redirect: '/farmer-payment-auto-generate',
        meta: {
          onDeniedRoute: 'login',
          require_auth: true,
        },
        children: [
          {
            name: route_names.farmerPaymentAutoGenerate,
            path: '/farmer-payment-auto-generate',
            component: () => import('@/views/app/farmer-payment/AutoGenerate')
          }
        ],
      },
      {
        name: route_names.billPayments,
        path: '/bill-payments',
        component: layout,
        redirect: '/bill-payments',
        meta: {
          onDeniedRoute: 'login',
          require_auth: true,
        },
        children: [
          {
            name: route_names.billPayments,
            path: '/bill-payments',
            component: () => import('@/views/app/bill-payments/BillPayments')
          }
        ],
      },
       {
        name: route_names.billPaymentDetials,
        path: '/bill-payment-batch-detail/:id',
        component: layout,
        redirect: '/bill-payment-batch-detail/:id',
        meta: {
          onDeniedRoute: 'login',
          require_auth: true,
        },
        children: [
          {
            name: route_names.billPaymentDetials,
            path: '/bill-payment-batch-detail/:id',
            component: () => import('@/views/app/bill-payments/BatchDetail')
          }
        ],
      },
       {
        name: route_names.topupPaymentDetails,
        path: '/topup-payment-batch-detail/:id',
        component: layout,
        redirect: '/topup-payment-batch-detail/:id',
        meta: {
          onDeniedRoute: 'login',
          require_auth: true,
        },
        children: [
          {
            name: route_names.topupPaymentDetails,
            path: '/topup-payment-batch-detail/:id',
            component: () => import('@/views/app/topup-payments/BatchDetail')
          }
        ],
      },
      {
        name: route_names.topupPayments,
        path: '/topup-payments',
        component: layout,
        redirect: '/topup-payments',
        meta: {
          onDeniedRoute: 'login',
          require_auth: true,
        },
        children: [
          {
            name: route_names.topupPayments,
            path: '/topup-payments',
            component: () => import('@/views/app/topup-payments/TopupPayments')
          }
        ],
      },
      {
      name: route_names.telcos,
      path: '/telcos',
      component: layout,
      redirect: '/telcos',
      meta: {
        can: 'topup-payment',
        onDeniedRoute: 'login',
        require_auth: true,
        title : 'Telcos',
      },
      children: [
        {
          name: route_names.telcos,
          path: '/telcos',
          component: () => import('@/views/app/telcos/Telco.vue')
        }
      ],
    },
    {
      name: route_names.billerDetail,
      path: '/billerDetail',
      component: layout,
      redirect: '/billerDetail',
      meta: {
        can: 'bill-payment',
        onDeniedRoute: 'login',
        require_auth: true,
        title : 'Biller Addition',
      },
      children: [
        {
          name: route_names.billerDetail,
          path: '/billerDetail',
          component: () => import('@/views/app/biller-addition/BillerAddition.vue')
        }
      ],
    },

    {
      name: route_names.billerDetail,
      path: '/billerDetail',
      component: layout,
      redirect: '/billerDetail',
      meta: {
        can: 'bill-payment',
        onDeniedRoute: 'login',
        require_auth: true,
        title : 'Biller Addition',
      },
      children: [
        {
          name: route_names.billerDetail,
          path: '/billerDetail',
          component: () => import('@/views/app/biller-addition/BillerAddition.vue')
        }
      ],
    },

    {
      name: route_names.ibftPaymentsBatches,
      path: '/ibft-payment-batches',
      component: layout,
      redirect: '/ibft-payment-batches',
      meta: {
        can: 'ibft-payment',
        onDeniedRoute: 'login',
        require_auth: true,
        title : 'IBFT Payments',
      },
      children: [
        {
          name: route_names.ibftPaymentsBatches,
          path: '/ibft-payment-batches',
          component: () => import('@/views/app/ibft-payments/AllBatches.vue')
        }
      ],
    },

    {
      name: route_names.ibftPaymentsBatchesDetail,
      path: '/ibft-payment-batch-detail/:id',
      component: layout,
      redirect: '/ibft-payment-batch-detail/:id',
      meta: {
        can: 'view-ibft-batch-detail',
        onDeniedRoute: 'login',
        require_auth: true,
        title : 'IBFT Payments',
      },
      children: [
        {
          name: route_names.ibftPaymentsBatchesDetail,
          path: '/ibft-payment-batch-detail/:id',
          component: () => import('@/views/app/ibft-payments/BatchDetail.vue')
        }
      ],
    },    

    {
      name: route_names.w2wCreditPaymentBatches,
      path: '/w2w-credit-payment-batches',
      component: layout,
      redirect: '/w2w-credit-payment-batches',
      meta: {
        can: 'w2w-credit-payment',
        onDeniedRoute: 'login',
        require_auth: true,
        title : 'W2W Cedit Payment',
      },
      children: [
        {
          name: route_names.w2wCreditPaymentBatches,
          path: '/w2w-credit-payment-batches',
          component: () => import('@/views/app/w2w-credit-payments/AllBatches.vue')
        }
      ],
    },

    {
      name: route_names.w2wCreditPaymentsBatchDetail,
      path: '/w2w-credit-payment-batch-detail/:id',
      component: layout,
      redirect: '/w2w-credit-payment-batch-detail/:id',
      meta: {
        can: 'view-w2w-credit-batch-detail',
        onDeniedRoute: 'login',
        require_auth: true,
        title : 'W2W Cedit Payment Detail',
      },
      children: [
        {
          name: route_names.w2wCreditPaymentsBatchDetail,
          path: '/w2w-credit-payment-batch-detail/:id',
          component: () => import('@/views/app/w2w-credit-payments/BatchDetail.vue')
        }
      ],
    }, 

    
    {
      name: route_names.w2wDebitPaymentBatches,
      path: '/w2w-debit-payment-batches',
      component: layout,
      redirect: '/w2w-debit-payment-batches',
      meta: {
        can: 'w2w-debit-payment',
        onDeniedRoute: 'login',
        require_auth: true,
        title : 'W2W Debit Payment',
      },
      children: [
        {
          name: route_names.w2wDebitPaymentBatches,
          path: '/w2w-debit-payment-batches',
          component: () => import('@/views/app/w2w-debit-payments/AllBatches.vue')
        }
      ],
    },

    {
      name: route_names.w2wDebitPaymentsBatchDetail,
      path: '/w2w-debit-payment-batch-detail/:id',
      component: layout,
      redirect: '/w2w-debit-payment-batch-detail/:id',
      meta: {
        can: 'view-w2w-debit-batch-detail',
        onDeniedRoute: 'login',
        require_auth: true,
        title : 'W2W Debit Payment Detail',
      },
      children: [
        {
          name: route_names.w2wDebitPaymentsBatchDetail,
          path: '/w2w-debit-payment-batch-detail/:id',
          component: () => import('@/views/app/w2w-debit-payments/BatchDetail.vue')
        }
      ],
    }, 

   

	],
});



router.beforeEach((to,from,next) => {
  let require_auth = to.matched.some((record) => record.meta.require_auth)
  let not_require_auth = to.matched.some((record) => record.meta.not_require_auth)
  let token = !!STORAGE.TOKEN.get()

  if (STORAGE.USER.get() && (!STORAGE.USER.get().data.is_password_verified) && to.name != "set-password") {
    // next({
      // name: route_names.set_password
    // })
  }
  else {
    next()
  }

  if(require_auth && !token){
    next({
      name: route_names.auth,
    })
    } else if(not_require_auth && token){
    next({
      name: route_names.home,
    })
    } else if(to.matched.length==0 && token){
    next({
      name: route_names.home,
    })
    } else if(to.matched.length==0 && !token){
    next({
      name: route_names.auth,
    })
  } else {
      next()
  }
});

export function addRoutesAgainstUser(){
  
  //router.addRoute(route_names.dashboard)
}
export function removeRoutesAfterUserLogout(){
   router.removeRoute(route_names.dashboard,toturials_routes)
}

export default router;