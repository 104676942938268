<template>
  
<div  
  class="modal fade" 
  id="editModalUser" 
  ref="UpdateJobDetails" 
  aria-hidden="true" 
  aria-labelledby="UpdateJobDetailsToggleLabel" 
  tabindex="-1"
> 
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row mb-4">
          <div class="col">
            <h2> Update Job Details </h2>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label
                  class="label block-label"
                  for="position"
              >
                Position
                <em>*</em>
              </label>
              <input
                type="text"
                class="form-control"
                id="position"
                required
                v-model="form.position"
                placeholder="Position"
                @input="removeValidation('position')"
              />
              <span
                  class="text-danger"
                  v-if="validations.position"
              >
                {{validations.position[0]}}
              </span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label
                  class="label block-label"
                  for="employee_code"
              >
                Employee Code
                <em>*</em>
              </label>
              <input
                  type="text"
                  class="form-control"
                  required
                  id="employee_code"
                  v-model="form.employee_code"
                  placeholder="Employee Code"
                  @input="removeValidation('employee_code')"
              />
              <span
                  class="text-danger"
                  v-if="validations.employee_code"
              >
                {{validations.employee_code[0]}}
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label
                  class="label block-label"
                  for="report_to"
              >
                Report TO
                <em>*</em>
              </label>
              <input
                  type="text"
                  name="report_to"
                  id="report_to"
                  class="form-control"
                  required
                  v-model="form.report_to"
                  placeholder="Report To"
                  @input="removeValidation('report_to')"
              />
              <span
                  class="text-danger"
                  v-if="validations.report_to"
              >
                {{validations.report_to[0]}}
              </span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label
                  class="label block-label"
                  for="office_address"
              >
                Office Address
                <em>*</em>
              </label>
              <input
                  type="text"
                  name="office_address"
                  class="form-control"
                  required
                  v-model="form.office_address"
                  id="office_address"
                  placeholder="Office Address"
                  @input="removeValidation('office_address')"
              />
              <span
                  class="text-danger"
                  v-if="validations.office_address"
              >
                {{validations.office_address[0]}}
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label
                  class="label block-label"
                  for="hiring_date"
              >
                Hiring Date
                <em>*</em>
              </label>
              <input
                type="date"
                name="hiring_date"
                class="form-control"
                required
                v-model="form.hiring_date"
                id="hiring_date"
                placeholder="Hiring Date"
                @input="removeValidation('hiring_date')"
              />
                <span class="text-danger" v-if="validations?.hiring_date">{{validations?.hiring_date[0]}}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label
                  class="label block-label"
                  for="gross_salary"
              >
                Gross Salary
                <em>*</em>
              </label>
              <input
                  type="number"
                  name="gross_salary"
                  class="form-control"
                  required
                  v-model="form.gross_salary"
                  id="gross_salary"
                  placeholder="Gross Salary"
                  @input="removeValidation('gross_salary')"
              />
                <span class="text-danger" v-if="validations?.gross_salary">{{validations?.gross_salary[0]}}</span>    
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label
                  class="label block-label"
                  for="eobi"
              >
                EOBI
                <em>*</em>
              </label>
              <input
                  type="number"
                  name="eobi"
                  class="form-control"
                  required
                  v-model="form.eobi"
                  id="eobi"
                  placeholder="Other Deduction"
                  @input="removeValidation('eobi')"
              />
              <span class="text-danger" v-if="validations?.eobi">{{validations?.eobi[0]}}</span>
            </div>
          </div>


          <div class="col-md-6">
            <div class="form-group">
              <label
                  class="label block-label"
                  for="provident_fund"
              >
                Provident Fund
                <em>*</em>
              </label>
              <input
                    type="number"
                    name="provident_fund"
                    class="form-control"
                    required
                    v-model="form.provident_fund"
                    id="provident_fund"
                    placeholder="Provident Fund"
                    @input="removeValidation('provident_fund')"
                />
                <span class="text-danger" v-if="validations?.provident_fund">{{validations?.provident_fund[0]}}</span>    
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label
                  class="label block-label"
                  for="other_deduction"
              >
                Other Deduction
                <em>*</em>
              </label>
              <input
                    type="number"
                    name="other_deduction"
                    class="form-control"
                    required
                    v-model="form.other_deduction"
                    id="other_deduction"
                    placeholder="Other Deduction"
                    @input="removeValidation('other_deduction')"
                />
                <span class="text-danger" v-if="validations?.other_deduction">{{validations?.other_deduction[0]}}</span>    
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label
                  class="label block-label"
                  for="income_tax"
              >
                Income Tax
                <em>*</em>
              </label>
              <input
                    type="number"
                    name="income_tax"
                    class="form-control"
                    required
                    v-model="form.income_tax"
                    id="income_tax"
                    placeholder="Income Tax"
                    @input="removeValidation('income_tax')"
                />
                <span class="text-danger" v-if="validations?.income_tax">{{validations?.income_tax[0]}}</span>    
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label
                  class="label block-label"
                  for="net_salary"
              >
                Net Salary
                <em>*</em>
              </label>
              <input
                  disabled
                  type="number"
                  name="net_salary"
                  class="form-control"
                  required
                  v-model="form.net_salary"
                  id="net_salary"
                  placeholder="Net Salary"
                  @input="removeValidation('net_salary')"
              />
              <span class="text-danger" v-if="validations?.net_salary">{{validations?.net_salary[0]}}</span>
            </div>
          </div>


          <div class="col-md-6">
            <div class="form-group">
              <label
                  class="label block-label"
                  for="employee_type"
              >
                Employee Type
                <em>*</em>
              </label>
              <select 
                  v-model="form.employee_type" 
                  class="form-control form-select"
                  @change="removeValidation('employee_type')">
                  <option> -- Select Employee Type -- </option>
                  <option  value="permanent"> Permanent </option>
                  <option value="contract"> Contract </option>
                <option value="temporary"> Temporary </option>
                <option value="part_time"> Part Time </option>
                <option value="other"> Other </option>
              </select>
              <!-- <input
                    type="text"
                    name="employee_type"
                    class="form-control"
                    required
                    v-model="form.employee_type"
                    id="employee_type"
                    placeholder="Employee Type"
                /> -->
                <span class="text-danger" v-if="validations?.employee_type">{{validations?.employee_type[0]}}</span>    
            </div>
          </div>
        </div>
        <div class="row form-action-btns align-right mt-3">
          <div class="col">
            <button
                @click="this.$parent.jobDetails()"
                data-bs-dismiss="modal" aria-label="Close"
                type="button"
                class="btn btn-primary"
            >
              Close
            </button>
            <button
                type="submit"
                @click="updateJobDetails()"
                class="btn btn-primary ms-2 active"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>

import {API,STORAGE} from "@/services";
import Swal from "sweetalert2";
import { Modal } from 'bootstrap';
import {Helpers} from "@/services/helpers.service";

export default {
  data(){
    return {
      modal: null,
      validations:{},
      form: {},
      employeeID : ''
    }
  },
  created(){},
  methods: {
    removeValidation(field) {
      if (this.validations[field]) {
        delete this.validations[field];
      }
    },
    updateJobDetails(){
      let vm = this;

      if(vm.form.gross_salary=='' || vm.form.gross_salary === null){
       Swal.fire(
         'Oops...',
         'Gross Salary is required',
         'error'
       )

        return false;
      }
      if (vm.form.gross_salary<=0){
        Swal.fire(
          'Oops...',
          'Gross Salary must be greater than 0',
          'error'
        )

        return false;

      }

      if(vm.form.net_salary=='' || vm.form.net_salary === null){
        Swal.fire(
          'Oops...',
          'Net Salary is required',
          'error'
        )

        return false;
      }
      if(vm.form.net_salary<=0){
        Swal.fire(
          'Oops...',
          'Net Salary must be greater than 0',
          'error'
        )
        return false;
      }
      if(vm.form.provident_fund<0){
        Swal.fire(
          'Oops...',
          'Provident Fund must be greater than 0',
          'error'
        )
        return false;
      }
      if(vm.form.other_deduction<0){
       Swal.fire(
         'Oops...',
         'Other Deduction must be greater than 0',
         'error'
       )
        return false;
      }
      if(vm.form.income_tax<0){
        Swal.fire(
          'Oops...',
          'Income Tax must be greater than 0',
          'error'
        )
        return false;
      }
      if(vm.form.eobi<0){
        Swal.fire(
          'Oops...',
          'EOBI must be greater than 0',
          'error'
        )
        return false;
      }
      vm.form.gross_salary = Helpers.rupeesToPaisa(vm.form.gross_salary)
      vm.form.net_salary = Helpers.rupeesToPaisa(vm.form.net_salary)
      vm.form.provident_fund = Helpers.rupeesToPaisa(vm.form.provident_fund)
      vm.form.other_deduction = Helpers.rupeesToPaisa(vm.form.other_deduction)
      vm.form.income_tax = Helpers.rupeesToPaisa(vm.form.income_tax)
      vm.form.eobi = Helpers.rupeesToPaisa(vm.form.eobi)
      API.endpoints.user.updateEmployeeJobDetails(vm.form, vm.employeeID)
      .then((response) => {
        Swal.fire("", "Data updated successfully", "success");
          vm.$emit("update");
          vm.modal.hide(); 
      })
      .catch((ex)=>{
        vm.form.gross_salary = Helpers.paisaTorupees(vm.form.gross_salary)
        vm.form.net_salary = Helpers.paisaTorupees(vm.form.net_salary)
        vm.form.provident_fund = Helpers.paisaTorupees(vm.form.provident_fund)
        vm.form.other_deduction = Helpers.paisaTorupees(vm.form.other_deduction)
        vm.form.income_tax = Helpers.paisaTorupees(vm.form.income_tax)
        vm.form.eobi = Helpers.paisaTorupees(vm.form.eobi)
        if(ex.response.status==422){
            vm.validations=(ex.response.data.errors);
        }
      })
    },
    beforeOpen(){
      this.validations = {};
    }
  },
  watch:{
    validations:function(){
    }
  },
  mounted(){
      this.modal = new Modal(this.$refs.UpdateJobDetails)
      this.modal._element.addEventListener('show.bs.modal', () => {
        this.$emit('modal-show')
        this.beforeOpen()
      })
    },
  watch:{
    'form.gross_salary'  : function (val) {
      this.form.net_salary = val-(this.form.provident_fund+this.form.other_deduction+this.form.income_tax+this.form.eobi);
    },
    'form.provident_fund'  : function (val) {
      this.form.net_salary = this.form.gross_salary-(val+this.form.other_deduction+this.form.income_tax+this.form.eobi);
    },
    'form.other_deduction'  : function (val) {
      this.form.net_salary = this.form.gross_salary-(this.form.provident_fund+val+this.form.income_tax+this.form.eobi);
    },
    'form.income_tax'  : function (val) {
      this.form.net_salary = this.form.gross_salary-(val+this.form.other_deduction+this.form.provident_fund+this.form.eobi);
    },
    'form.eobi'  : function (val) {
      this.form.net_salary = this.form.gross_salary-(val+this.form.other_deduction+this.form.provident_fund+this.form.income_tax);
    },
    'form.net_salary'  : function (val) {
      if(val > 0){
        this.removeValidation('net_salary');
      }
    },
  }
}
</script>
    