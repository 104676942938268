<template>
  
<div  
  class="modal fade" 
  id="AddEmploymentHistoryModalUser" 
  ref="AddEmploymentHistory" 
  aria-hidden="true" 
  aria-labelledby="AddEmploymentHistoryToggleLabel" 
  tabindex="-1"
> 
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row mb-4">
          <div class="col">
            <h2> Add Employment History </h2>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label
                  class="label block-label"
                  for="company_name"
              >
                Company Name
                <em>*</em>
              </label>
              <input
                  type="text"
                  class="form-control"
                  id="company_name"
                  required
                  v-model="form.company_name"
                  placeholder="company name"
                  @input="removeValidation('company_name')" 
              />
              <span
                  class="text-danger"
                  v-if="validations.company_name"
              >
                {{validations.company_name[0]}}
              </span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label
                  class="label block-label"
                  for="job_title"
              >
                Job Title
                <em>*</em>
              </label>
              <input
                  type="text"
                  class="form-control"
                  required
                  id="job_title"
                  v-model="form.job_title"
                  placeholder="Job Title"
                  @input="removeValidation('job_title')"
              />
              <span
                  class="text-danger"
                  v-if="validations.job_title"
              >
                {{validations.job_title[0]}}
              </span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label
                  class="label block-label"
                  for="start_date"
              >
                Start Date
                <em>*</em>
              </label>
              <input
                  type="date"
                  name="start_date"
                  id="start_date"
                  class="form-control"
                  required
                  v-model="form.start_date"
                  placeholder="Start Date"
                  @input="removeValidation('start_date')"
              />
              <span
                  class="text-danger"
                  v-if="validations.start_date"
              >
                {{validations.start_date[0]}}
              </span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label
                  class="label block-label"
                  for="end_date"
              >
                End Date 
              </label>
              <input
                  type="date"
                  name="end_date"
                  id="end_date"
                  class="form-control"
                  required
                  v-model="form.end_date"
                  placeholder="End Date"
                  @input="removeValidation('end_date')"
              />
              <span
                  class="text-danger"
                  v-if="validations.end_date"
              >
                {{validations.end_date[0]}}
              </span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label
                  class="label block-label"
                  for="salary"
              >
                Salary
                <em>*</em>
              </label>
              <input
                  type="number"
                  name="salary"
                  id="salary"
                  class="form-control"
                  required
                  v-model="form.salary"
                  placeholder="Salary"
                  @input="removeValidation('salary')"
              />
              <span
                  class="text-danger"
                  v-if="validations.salary"
              >
                {{validations.salary[0]}}
              </span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label
                  class="label block-label"
                  for="status"
              >
                Status
                <em>*</em>
              </label>
              <select
                  type="number"
                  name="status"
                  id="status"
                  class="form-control form-select"
                  v-model="form.status"
                  @change="removeValidation('status')"
              >   
                  <option value="" selected> -- Select Status -- </option>
                  <option value="contract"> Contract </option>
                  <option value="permanent"> Permanent </option>
              </select>
              <span
                  class="text-danger"
                  v-if="validations.status"
              >
                {{validations.status[0]}}
              </span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label
                  class="label block-label"
                  for="experience_letter"
              >
                Experience Letter 
              </label>
              <input 
                type="file" 
                class="form-control" 
                ref="fileInput" 
                @change="getExperienceLetter($event)" 
                @input="removeValidation('experience_letter')" 
              />
              <span
                  class="text-danger"
                  v-if="validations.experience_letter"
              >
                {{validations.experience_letter[0]}}
              </span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label
                  class="label block-label"
                  for="description"
              >
                Description 
              </label>
              <textarea
                  type="date"
                  name="description"
                  id="description"
                  class="form-control"
                  required
                  v-model="form.description"
                  placeholder="Description"
                  @input="removeValidation('description')"
              ></textarea>
              <span
                  class="text-danger"
                  v-if="validations.description"
              >
                {{validations.description[0]}}
              </span>
            </div>
          </div>
        </div>  
        <div class="row form-action-btns mt-3 align-right">
          <div class="col">
            <button
                data-bs-dismiss="modal" aria-label="Close"
                type="button"
                class="btn btn-primary"
            >
              Close
            </button>
            <button
                type="submit"
                @click="addEmploymentHistory()"
                class="btn btn-primary ms-2 active"
            >
              Add Employement History
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>

import {API,STORAGE} from "@/services";
import Swal from "sweetalert2";
import { Modal } from 'bootstrap';

export default {
  data(){
    return {
      modal: null,
      validations:{},
      form: {
        company_name: '',
        job_title: '',
        start_date: '',
        end_date: '',
        description: '',
        experience_letter: '',
        salary: '',
        status: ''
      },
      employeeID : ''
    }
  },
  created(){},
  methods: {
    removeValidation(field) {
      if (this.validations[field]) {
        delete this.validations[field];
      }
    },
    getExperienceLetter(event){
      this.form.experience_letter = event.target.files[0]
    },
    addEmploymentHistory(){
      let vm = this;
      API.endpoints.user.addEmploymentHistory(vm.form, vm.employeeID)
      .then((response) => {
        vm.$parent.employmentHistory();
          vm.$emit("update");
          vm.modal.hide(); 
      })
      .catch((ex)=>{
        if(ex.response.status==422){
            vm.validations=(ex.response.data.errors);
        }
      })
    },
    beforeOpen(){
      this.validations = {};
    }
  },
  watch:{
    validations:function(){
    }
  },
  mounted(){
      this.modal = new Modal(this.$refs.AddEmploymentHistory)
      this.modal._element.addEventListener('show.bs.modal', () => {
        this.$emit('modal-show')
        this.beforeOpen()
      })
    }
}
</script>
    