<template>
  
<div  
  class="modal fade" 
  id="AddKycModalUser" 
  ref="AddKyc" 
  aria-hidden="true" 
  aria-labelledby="AddKycToggleLabel" 
  tabindex="-1"
> 
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row mb-4">
          <div class="col">
            <h2> Add Personal Details (KYC) </h2>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label
                  class="label block-label"
                  for="position"
              >
                Father / Husband Name
                <em>*</em>
              </label>
              <input
                  type="text"
                  class="form-control"
                  id="father_husband_name"
                  required
                  v-model="form.father_husband_name"
                  placeholder="Father Name"
              />
              <span
                  class="text-danger"
                  v-if="validations?.father_husband_name"
              >
                {{validations?.father_husband_name[0]}}
              </span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label
                  class="label block-label"
                  for="employee_code"
              >
                Mother Name
                <em>*</em>
              </label>
              <input
                  type="text"
                  class="form-control"
                  required
                  id="mother_name"
                  v-model="form.mother_name"
                  placeholder="Mother Name"
              />
              <span
                  class="text-danger"
                  v-if="validations.employee_code"
              >
                {{validations.employee_code[0]}}
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label
                  class="label block-label"
                  for="gender"
              >
                Gender
                <em>*</em>
              </label>
              <select 
                  v-model="form.gender" 
                  class="form-control form-select">
                  <option value="" selected> -- Gender -- </option>
                  <option  value="male"> Male </option>
                  <option value="female"> Female </option>
              </select>
              
              <span
                  class="text-danger"
                  v-if="validations?.gender"
              >
                {{validations?.gender[0]}}
              </span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label
                  class="label block-label"
                  for="date_of_birth"
              >
                Date of Birth
                <em>*</em>
              </label>
              <input
                  type="date"
                  class="form-control"
                  required
                  id="date_of_birth"
                  v-model="form.date_of_birth"
                  placeholder="Date of Birth"
              />
              <span
                  class="text-danger"
                  v-if="validations?.date_of_birth"
              >
                {{validations?.date_of_birth[0]}}
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label
                  class="label block-label"
                  for="cnic"
              >
                CNIC
                <em>*</em>
              </label>
              <input
                  type="text"
                  class="form-control"
                  required
                  id="cnic"
                  v-model="form.cnic"
                  placeholder="CNIC"
              />
              <span
                  class="text-danger"
                  v-if="validations?.cnic"
              >
                {{validations?.cnic[0]}}
              </span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label
                  class="label block-label"
                  for="cnic_issuance_date"
              >
                CNIC Issuance Date
                <em>*</em>
              </label>
              <input
                  type="date"
                  class="form-control"
                  required
                  id="cnic_issuance_date"
                  v-model="form.cnic_issuance_date"
                  placeholder="Date of Birth"
              />
              <span
                  class="text-danger"
                  v-if="validations?.cnic_issuance_date"
              >
                {{validations?.cnic_issuance_date[0]}}
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label
                  class="label block-label"
                  for="cnic_expiry_date"
              >
                CNIC Expiry Date
                <em>*</em>
              </label>
              <input
                  type="date"
                  class="form-control"
                  required
                  id="cnic_expiry_date"
                  v-model="form.cnic_expiry_date"
                  placeholder="Date of Birth"
              />
              <span
                  class="text-danger"
                  v-if="validations?.cnic_expiry_date"
              >
                {{validations?.cnic_expiry_date[0]}}
              </span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label
                  class="label block-label"
                  for="current_address"
              >
                Current Address
                <em>*</em>
              </label>
              <input
                  type="text"
                  name="current_address"
                  id="current_address"
                  class="form-control"
                  required
                  v-model="form.current_address"
                  placeholder="Report To"
              />
              <span
                  class="text-danger"
                  v-if="validations?.current_address"
              >
                {{validations?.current_address[0]}}
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label
                  class="label block-label"
                  for="permanent_address"
              >
                Permanent Address
                <em>*</em>
              </label>
              <input
                  type="text"
                  name="permanent_address"
                  class="form-control"
                  required
                  v-model="form.permanent_address"
                  id="permanent_address"
                  placeholder="Permanent Address"
              />
              <span
                  class="text-danger"
                  v-if="validations?.permanent_address"
              >
                {{validations?.permanent_address[0]}}
              </span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label
                  class="label block-label"
                  for="province_id"
              >
                Province
                <em>*</em>
              </label>
              <select 
                  v-model="form.province_id" 
                  class="form-control form-select">
                  <option value="" selected> -- Select Province -- </option>
                  <option  :value="province.provc_id" v-for="(province,key) in provinces" :key="key"> {{ province.name }} </option>
              </select>
              
              <span
                  class="text-danger"
                  v-if="validations?.province_id"
              >
                {{validations?.province_id[0]}}
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label
                  class="label block-label"
                  for="city_id"
              >
                City
                <em>*</em>
              </label>
              <select 
                  v-model="form.city_id" 
                  class="form-control form-select">
                  <option value="" selected> -- Select City -- </option>
                  <option  :value="city.city_id" v-for="(city,key) in cities" :key="key"> {{ city.name }} </option>
              </select>
              <span
                  class="text-danger"
                  v-if="validations?.city_id"
              >
                {{validations?.city_id[0]}}
              </span>
            </div>
          </div>
        </div>
        <div class="row form-action-btns align-right">
          <div class="col">
            <button
                data-bs-dismiss="modal" aria-label="Close"
                type="button"
                class="btn btn-primary"
            >
              Close
            </button>
            <button
                type="submit"
                @click="addPersonalDetails()"
                class="btn btn-primary ms-2 active"
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>

import {API,STORAGE} from "@/services";
import Swal from "sweetalert2";
import { Modal } from 'bootstrap';

export default {
  data(){
    return {
      modal: null,
      validations:{},
      form: {
        cnic: '',
        father_husband_name: '',
        mother_name: '',
        current_address: '',
        permanent_address: '',
        gender: '',
        date_of_birth: '',
        cnic_issuance_date: '',
        cnic_expiry_date: '',
        province_id: '',
        city_id: ''
      },
      employeeID : '',
      cities: {},
      provinces: {}
    }
  },
  created(){},
  methods: {
    addPersonalDetails(){
      let vm = this;
      API.endpoints.user.addPersonalDetails(vm.form, vm.employeeID)
      .then((response) => {
          vm.$emit("update");
          vm.modal.hide(); 
      })
      .catch((ex)=>{
        if(ex.response.status==422){
            vm.validations=(ex.response.data.errors);
        }
      })
    },
    getCities(){
      let vm = this;
      API.endpoints.user.cityListing()
      .then((data) => {
          vm.cities = data?.data?.data ?? null
      })
      .catch((ex)=>{ 
          reject(ex)
        }).finally(()=>{
          vm.loader=false
      })
    },
    getProvince(){
      let vm = this;
      API.endpoints.user.provinceListing()
      .then((data) => {
          vm.provinces = data?.data?.data ?? null
      })
      .catch((ex)=>{ 
          reject(ex)
        }).finally(()=>{
          vm.loader=false
      })
    },
    beforeOpen(){
      this.validations = {};
      this.getCities()
      this.getProvince()
    }
  },
  watch:{
    validations:function(){
    }
  },
  mounted(){
      this.modal = new Modal(this.$refs.AddKyc)
      this.modal._element.addEventListener('show.bs.modal', () => {
        this.$emit('modal-show')
        this.beforeOpen()
      })
    }
}
</script>
    