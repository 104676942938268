<template>
  
<div  
  class="modal fade" 
  id="AddEducationHistoryModal" 
  ref="AddEducationHistory" 
  aria-hidden="true" 
  aria-labelledby="AddEducationHistoryToggleLabel" 
  tabindex="-1"
> 
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row mb-4">
          <div class="col">
            <h2> Add Education History </h2>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label
                  class="label block-label"
                  for="institution_name"
              >
                Institution Name
                <em>*</em>
              </label>
              <input
                  type="text"
                  class="form-control"
                  id="institution_name"
                  required
                  v-model="form.institution_name"
                  placeholder="Institution Name"
                  @input="removeValidation('institution_name')"
              />
              <span
                  class="text-danger"
                  v-if="validations.institution_name"
              >
                {{validations.institution_name[0]}}
              </span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label
                  class="label block-label"
                  for="field_of_study"
              >
                Field of Study
                <em>*</em>
              </label>
              <input
                  type="text"
                  class="form-control"
                  required
                  id="field_of_study"
                  v-model="form.field_of_study"
                  placeholder="Field of Study" 
                  @input="removeValidation('field_of_study')"
              />
              <span
                  class="text-danger"
                  v-if="validations.field_of_study"
              >
                {{validations.field_of_study[0]}}
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label
                  class="label block-label"
                  for="obtained_marks"
              >
                Obtained Marks
                <em>*</em>
              </label>
              <input
                  type="number"
                  name="obtained_marks"
                  id="obtained_marks"
                  class="form-control"
                  required
                  v-model="form.obtained_marks"
                  placeholder="Obtained Marks"
                  @input="removeValidation('obtained_marks')"
              />
              <span
                  class="text-danger"
                  v-if="validations.obtained_marks"
              >
                {{validations.obtained_marks[0]}}
              </span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label
                  class="label block-label"
                  for="total_marks"
              >
                Total Marks
                <em>*</em>
              </label>
              <input
                  type="number"
                  name="total_marks"
                  id="total_marks"
                  class="form-control"
                  required
                  v-model="form.total_marks"
                  placeholder="Total Marks"
                  @input="removeValidation('total_marks')"
              />
              <span
                  class="text-danger"
                  v-if="validations.total_marks"
              >
                {{validations.total_marks[0]}}
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label
                  class="label block-label"
                  for="start_date"
              >
                Start Date
                <em>*</em>
              </label>
              <input
                  type="date"
                  name="start_date"
                  id="start_date"
                  class="form-control"
                  required
                  v-model="form.start_date"
                  placeholder="Start Date"
                  @input="removeValidation('start_date')"
              />
              <span
                  class="text-danger"
                  v-if="validations.start_date"
              >
                {{validations.start_date[0]}}
              </span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label
                  class="label block-label"
                  for="end_date"
              >
                End Date
                <em>*</em>
              </label>
              <input
                  type="date"
                  name="end_date"
                  id="end_date"
                  class="form-control"
                  required
                  v-model="form.end_date"
                  placeholder="End Date"
                  @input="removeValidation('end_date')"
              />
              <span
                  class="text-danger"
                  v-if="validations.end_date"
              >
                {{validations.end_date[0]}}
              </span>
            </div>
          </div>
        </div>
          
        <div class="row mt-3 form-action-btns align-right">
          <div class="col">
            <button
                data-bs-dismiss="modal" aria-label="Close"
                type="button"
                class="btn btn-primary"
            >
              Close
            </button>
            <button
                type="submit"
                @click="addEducationHistory()"
                class="btn btn-primary ms-2 active"
            >
              Add Education History
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>

import {API,STORAGE} from "@/services";
import Swal from "sweetalert2";
import { Modal } from 'bootstrap';

export default {
  data(){
    return {
      modal: null,
      validations:{},
      form: {
        institution_name: '',
        field_of_study: '',
        obtained_marks: '',
        total_marks: '',
        start_date: '',
        end_date: ''
      },
      employeeID : ''
    }
  },
  created(){},
  methods: {
    removeValidation(field) {
      if (this.validations[field]) {
        delete this.validations[field];
      }
    },
    getExperienceLetter(event){
      this.form.experience_letter = event.target.files[0]
    },
    addEducationHistory(){
      let vm = this;
      API.endpoints.user.addUserEducationHistory(vm.form, vm.employeeID)
      .then((response) => {
        vm.$parent.educationHistory();
          vm.$emit("update");
          vm.modal.hide();

      })
      .catch((ex)=>{
        if(ex.response.status==422){
            vm.validations=(ex.response.data.errors);
        }
      })
    },
    beforeOpen(){
      this.validations = {};
    }
  },
  watch:{
    validations:function(){
    }
  },
  mounted(){
      this.modal = new Modal(this.$refs.AddEducationHistory)
      this.modal._element.addEventListener('show.bs.modal', () => {
        this.$emit('modal-show')
        this.beforeOpen()
      })
    }
}
</script>
    