<!--
<template>
<div class="content-pages-inner">
  <div class="page-top mb-1 mb-3 clearfix">
      <h2>My Account</h2>
  </div>

  <div class="pages-generic">
        <div class="form-holder">
          <div class="mb-3">
            <label class="label block-label" for="name">Name</label>
            <input id="name" name="name" type="text" v-model="form.name" class="form-control" autocomplete="off" placeholder="Enter full name" required />
            <span class="text-danger" v-if="validations.name">{{validations.name[0]}}</span>
          </div>
          <div class="mb-3" v-if="form.email">
            <label class="label block-label" for="email">Email</label>
            <input id="email" name="email" type="email" v-model="form.email" class="form-control" autocomplete="off" placeholder="Enter email" required disabled />
            <span class="text-danger" v-if="validations.email">{{validations.email[0]}}</span>
          </div>
          <div class="mb-3" v-if="form.company">
            <label class="label block-label" for="email">Company</label>
            <input id="company" name="company" type="company" v-model="form.company" class="form-control" autocomplete="off" placeholder="Enter company" required disabled />
            <span class="text-danger" v-if="validations.company">{{validations.company[0]}}</span>
          </div>
          <div class="mb-3" v-if="form.department">
            <label class="label block-label" for="email">Department</label>
            <input id="department" name="department" type="department" v-model="form.department" class="form-control" autocomplete="off" placeholder="Enter department" required disabled />
            <span class="text-danger" v-if="validations.department">{{validations.department[0]}}</span>
          </div>
          <div class="mb-3" v-if="form.designation">
            <label class="label block-label" for="email">Designation</label>
            <input id="designation" name="designation" type="designation" v-model="form.designation" class="form-control" autocomplete="off" placeholder="Enter designation" required disabled />
            <span class="text-danger" v-if="validations.designation">{{validations.designation[0]}}</span>
          </div>
          <div class="mb-3">
            <h5 class="mb-3" for="email">Account Verification Status</h5>
            <img title="Verified" src="verified.png" style="width: 5%;" v-if="$store?.state?.auth?.user?.data?.is_password_verified == 1">
            <img title="Not Verified" src="not_verified.png" style="width: 5%;" v-else>
          </div>
          <div class="mb-0">
            <button :disabled='isDisabled' @click="updateProfile()" class="btn btn-primary active">Save</button>
          </div>
        </div>
  </div>
</div>
</template>
  
  <script>

import { API } from '@/services';
import Swal from 'sweetalert2'
import { mapMutations } from 'vuex';
import { STORAGE } from "./../../../services/store.service";
import { SET_USER } from './../../../store/mutations'

export default {

  name: 'Profile',

  data() {
    return {
      loader : false,
      validations : {},
      form : {
        name : this?.$store?.state?.auth?.user?.data?.name,
        email : this?.$store?.state?.auth?.user?.data?.email,
        company : this?.$store?.state?.auth?.user?.data?.company_detail?.name,
        department : this?.$store?.state?.auth?.user?.data?.department?.name,
        designation : this?.$store?.state?.auth?.user?.data?.designation?.name
      }
    }
  },
  methods : {
    ...mapMutations([
      SET_USER
    ]),
    updateProfile: function () {
      let vm = this;
      vm.isDisabled = true;

      API.endpoints.user.updateProfile(this.form)
      .then((data) => {
        STORAGE.USER.set(data.data)
        this.SET_USER(data.data)
        Swal.fire('Success!', "Profile Updated Successfully!", 'success');
        vm.isDisabled = false;
      })
      .catch((ex) => {
        Swal.fire('Error!', "Something went wrong!", 'error');
        vm.isDisabled = false;
      });
    }
  }
}
</script>
-->



<template>
  <div>
    <div v-if="loader" class="loader" >
      <div class="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
    <template v-else>
      <div class="content-pages-inner pm-employee-main">
        <div class="page-top mb-1 mb-3 clearfix">
          <div class="pm-employee-info float-start">
            <div class="image-holder">
              <img src="assets/images/table-dummy-image.jpg" alt="" />
            </div>
            <div class="info">
              <strong class="title"> {{ user?.name }} </strong>
              <p> {{ user?.designation?.name }} </p>
            </div>
          </div>
          <a href="#" class="btn btn-secondary float-end d-none"> Save & Continue </a>
          <button class="btn btn-primary active float-end" @click="this.$router.go(-1)"> Back </button>
        </div>
        <div class="employee-view-tabs mb-4">
          <ul class="nav nav-tabs nav-tabs-2" id="myTab" role="tablist">
            <li class="nav-item">
              <a class="nav-link" @click.prevent="setActive('JobDetail', this.$refs.EmployeeDetail.jobDetails());" :class="{ active: isActive('JobDetail') }" href="#JobDetail">Job Detail</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" @click.prevent="setActive('PersonelDetail', this.$refs.KycDetail.getUserKYC())" :class="{ active: isActive('PersonelDetail') }" href="#PersonelDetail">Personal Detail (KYC)</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" @click.prevent="setActive('PersonelAccountDetail', this.$refs.KycDetail.getUserKYC())" :class="{ active: isActive('PersonelAccountDetail') }" href="#PersonelAccountDetail">My Account Detail</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" @click.prevent="setActive('PayDetail', this.$refs.BankAccountDetail.getUserBankDetails())" :class="{ active: isActive('PayDetail') }" href="#PayDetail">Bank Account Detail</a>
            </li>
            <li class="nav-item d-none">
              <a class="nav-link" @click.prevent="setActive('EmployementHistory', this.$refs.EmploymentHistory.employmentHistory())" :class="{ active: isActive('EmployementHistory') }" href="#EmployementHistory">Employment History</a>
            </li>
            <li class="nav-item d-none">
              <a class="nav-link" @click.prevent="setActive('EducationHistory', this.$refs.EducationHistory.educationHistory())" :class="{ active: isActive('EducationHistory') }" href="#EducationHistory">Education History</a>
            </li>
          </ul>
        </div>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade" :class="{ 'active show': isActive('JobDetail') }" id="JobDetail">
            <JobDetails ref="EmployeeDetail" isHide="1" />
          </div>
          <div class="tab-pane fade" :class="{ 'active show': isActive('PersonelAccountDetail') }" id="PersonelAccountDetail">
            <PersonelAccountDetail ref="KycDetail" />
          </div>
          <div class="tab-pane fade" :class="{ 'active show': isActive('PersonelDetail') }" id="PersonelDetail">
            <PersonalDetails ref="KycDetail" isHide="1" />
          </div>
          <div class="tab-pane fade" :class="{ 'active show': isActive('PayDetail') }" id="PayDetail">
            <BankAccountDetails ref="BankAccountDetail"  isHide="1" />
          </div>
          <div class="tab-pane fade d-none" :class="{ 'active show': isActive('EmployementHistory') }" id="EmployementHistory">
            <EmploymentHistory ref="EmploymentHistory" />
          </div>
          <div class="tab-pane fade d-none" :class="{ 'active show': isActive('EducationHistory') }" id="EducationHistory">
            <EducationHistory ref="EducationHistory" />
          </div>

        </div>
      </div>

    </template>
  </div>
</template>

<script>

import { API,STORAGE } from '@/services'
import { Tooltip } from 'bootstrap'
import JobDetails from "@/views/app/employee-detail/partials/JobDetails";
import PersonalDetails from "@/views/app/employee-detail/partials/PersonalDetails";
import PersonelAccountDetail from "@/views/app/user/UserAccountDetail";
import BankAccountDetails from "@/views/app/employee-detail/partials/BankAccountDetails";
import EmploymentHistory from "@/views/app/employee-detail/partials/EmploymentHistory";
import EducationHistory from "@/views/app/employee-detail/partials/EducationHistory";

export default {
  name: 'EducationDetail',
  data(){
    return {
      user : [],
      loader: true,
      showModal: false,
      items: null,
      page: 1,
      totalRecords: 0,
      value: null,
      activeItem: 'JobDetail',
      employeeId: this.$route.params.id,
      employeeName: ''
    }
  },
  components: {
    JobDetails,
    PersonalDetails,
    BankAccountDetails,
    EmploymentHistory,
    EducationHistory,
    PersonelAccountDetail
  },
  methods: {
    isActive (menuItem) {
      return this.activeItem === menuItem
    },
    setActive (menuItem) {
      this.activeItem = menuItem
    },
    fetchUserDetailById(id){
      let vm = this;
      API.endpoints.user.getUserDetailById(id)
          .then((response)=>{
            vm.user = response?.data?.data;
          })
          .catch((ex)=>{
            reject(ex)
          }).finally(()=>{
        vm.loader=false
      })
    },
  },

  created(){
    console.log(this.$route.params.id)
    this.fetchUserDetailById(this.$route.params.id);
  },
  mounted() {
    new Tooltip(document.body, {
      selector: "[data-bst-toggle='tooltip']",
    }),
        this.value = this.$route.params;
  }
}
</script>



