<template>
  
<div  
  class="modal fade" 
  id="UpdateBankDetailsModalUser" 
  ref="UpdateBankDetails" 
  aria-hidden="true" 
  aria-labelledby="UpdateBankDetailsToggleLabel" 
  tabindex="-1"
> 
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row mb-4">
          <div class="col">
            <h2> Update Job Details </h2>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label
                  class="label block-label"
                  for="bank_id"
              >
                Bank Name
                <em>*</em>
              </label> 
              <select
                  class="form-control form-select"
                  id="bank_id"
                  required
                  v-model="form.bank_id" 
                  :disabled="!banks.length || form.is_verified"
                  @change="removeValidation('bank_id')"
              >
                  <option value="" > -- Select Bank -- </option>
                  <option 
                    v-for="bank in banks" 
                    :value="bank.bank_id"
                    :key="bank.bank_id"
                  >
                    {{bank.name}}
                  </option>
              </select>
              <span
                  class="text-danger"
                  v-if="validations.bank_id"
              >
                {{validations.bank_id[0]}}
              </span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label
                  class="label block-label"
                  for="account_number"
              >
                Account Number
                <em>*</em>
              </label>
              <input
                  type="text"
                  :disabled="form.is_verified"
                  class="form-control"
                  required
                  id="account_number"
                  v-model="form.account_number"
                  placeholder="Account Number"
                  @input="removeValidation('account_number')"
              />
              <span
                  class="text-danger"
                  v-if="validations.account_number"
              >
                {{validations.account_number[0]}}
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label
                  class="label block-label"
                  for="account_tile"
              >
                Account title
                <em>*</em>
              </label>
              <input
                  type="text"
                  name="account_tile"
                  id="account_tile"
                  class="form-control"
                  :disabled="true"
                  required
                  v-model="form.account_title"
                  placeholder="Account Title"
                  @input="removeValidation('account_title')"
              />
              <span
                  class="text-danger"
                  v-if="validations.account_title"
              >
                {{validations.account_title[0]}}
              </span>
            </div>
          </div>

            <div class="col-md-6 mt-2">
              <button :disabled="form.account_number =='' || form.account_type=='' || form.is_verified"  class="btn btn-primary col-md-12 mt-4" @click="getAccountInquiry"> 
              <fa-icon v-if="btnReponseLoader" icon="fa-solid fa-circle-notch" spin /> <span v-else> Verify </span>
            </button>

            <!-- <button v-if="!form.is_verified" class="button btn btn-primary mt-4"  @click="getAccountInquiry()">Verify</button>             -->

          </div>
            <div class="col-md-6">
            <div class="form-group">
              <label
                  class="label block-label"
                  for="account_type"
              >
                Account Type
                <em>*</em>
              </label>
              <select
                  class="form-control form-select"
                  id="account_type"
                  :disabled="form.is_verified"
                  required
                  v-model="form.account_type"  
                  @change="removeValidation('account_type')"
              >
                  <option value="" selected> -- Select Account Type -- </option>
                  <option value="ibft"> ibft </option>
                  <option value="wallet"> wallet </option>
              </select>
              <span
                  class="text-danger"
                  v-if="validations.account_type"
              >
                {{validations.account_type[0]}}
              </span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label
                  class="label block-label"
                  for="account_type"
              >
                Status
                <em>*</em>
              </label>
              <select
                  class="form-control form-select"
                  id="account_type"
                  required
                  v-model="form.status" 
                  @change="removeValidation('status')"
              >
                <option value="active"> Active </option>
                <option value="inactive"> InActive </option>
              </select>
              <span
                  class="text-danger"
                  v-if="validations.status"
              >
                {{validations.status[0]}}
              </span>
            </div>
          </div>
        </div>
        <div class="row form-action-btns mt-3 align-right">
          <div class="col">
            <button
                data-bs-dismiss="modal" aria-label="Close"
                type="button"
                class="btn btn-primary"
            >
              Close
            </button>
            <button
                type="submit"
                @click="updateBankDetails()"
                class="btn btn-primary ms-2 active"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>

import {API,STORAGE} from "@/services";
import Swal from "sweetalert2";
import { Modal } from 'bootstrap';

export default {
  data(){
    return {
      modal: null,
      banks : [],
      validations:{},
      form: {
      },
      employeeID : '',
      titleFetchPayload: {
        account_type : "",
        account_number : "",
        bank_id : ""
      },
      btnReponseLoader: false,
    }
  },
  created(){},
  methods: {
    removeValidation(field) {
      if (this.validations[field]) {
        delete this.validations[field];
      }
    },
    fetchBanksList(){
          let vm = this;
          API.endpoints.banks.list()
          .then((data)=>{
              vm.banks = data?.data?.data ?? null
              console.log('vm.banks')
              console.log(vm.banks)
          })
          .catch((ex)=>{
              // reject(ex)
          }).finally(()=>{
              vm.loader=false
          })
    },
    getAccountInquiry() {
      let vms=this;
      vms.titleFetchPayload.account_type =  vms.form.account_type;
      vms.titleFetchPayload.account_number =  vms.form.account_number;
      this.btnReponseLoader = true
      if(vms.form.account_type === 'ibft') {
        vms.titleFetchPayload.bank_id = vms.form.bank_id;
      }

      API.endpoints.payments.fetchTitle(vms.titleFetchPayload)
          .then((response) => {

          if(response?.data?.data?.statusCode==400){
            Swal.fire("Error",response?.data?.data?.statusMessage,"error")
          }
          if (response?.data?.data?.statusCode==200){
          //  vms.form.account_title = response?.data?.data?.data?.accountTitle;
            vms.form.account_title = (vms.form.account_type == 'ibft') ? response?.data?.data?.data?.beneficiary?.accountTitle : response?.data?.data?.data?.accountTitle;
            vms.form.is_verified = 1;
          }

          })
          .catch((ex)=>{
            Swal.fire("Error",ex?.response_error?.message,"error")
          }).finally(()=>{
              this.btnReponseLoader = false
          });

    },
    updateBankDetails(){
      let vm = this;
      API.endpoints.user.updateUserBankDetails(vm.form, vm.employeeID)
      .then((response) => {
        vm.$parent.getUserBankDetails();
          vm.$emit("update");
          vm.modal.hide(); 
      })
      .catch((ex)=>{
        if(ex.response.status==422){
            vm.validations=(ex.response.data.errors);
        }
      })
    },
    beforeOpen(){
      this.validations = {};
      this.fetchBanksList();
    }
  },
  watch:{
    validations:function(){
    }
  },
  mounted(){
      this.modal = new Modal(this.$refs.UpdateBankDetails)
      this.modal._element.addEventListener('show.bs.modal', () => {
        this.$emit('modal-show')
        this.beforeOpen()
      })
    }
}
</script>
    