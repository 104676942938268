import router, { route_names, addRoutesAgainstUser } from '@/router'
import { API, STORAGE } from '@/services'
import { HELPERS } from '@/utils'
import { FETCH_USER, LOGIN, LOGOUT } from '../actions'
import { GET_TOKEN, GET_USER } from '../getters'
import { SET_TOKEN, SET_USER,SET_BALANCE } from '../mutations'
export const auth = {
  state: {
    user: {},
    token: '',
  },
  getters: {
    [GET_USER](state){ return state.user },
    [GET_TOKEN](state){ return state.token },
  },
  actions: {
    [LOGIN]({ commit,dispatch },data) { //data
      return new Promise((resolve,reject)=>{
        API.endpoints.auth.login({
          username: data?.username,
          password: data?.password,
          otp: data?.otp,
        })
        .then((data) => {

          STORAGE.TOKEN.set(data.data.access_token)
          commit(SET_TOKEN,data.data.access_token)
          resolve()

          dispatch("FETCH_USER");
    
        })
        .catch((ex)=>{
          console.log(ex)
          reject(ex)
        })
      })
    },
    [LOGOUT]({ commit }) {
      commit(SET_TOKEN)
      STORAGE.TOKEN.set('')
      STORAGE.USER.remove()
      commit(SET_USER)
      commit(SET_BALANCE)
      router.push({
        name: route_names.login
      })
    },
    [FETCH_USER]({ commit }) {
      return new Promise((resolve,reject)=>{
        API.endpoints.user.getbyid()
        .then(({ data })=> {
          localStorage.setItem("gl_balance", data.gl_balance);
          STORAGE.USER.set(data)
          commit(SET_USER,data)
          addRoutesAgainstUser()
          resolve()

          if (!data.data.is_password_verified) {
            router.push({
              name: route_names.set_password
            })
          }
          else{
            router.push({
              name: route_names.home
            });  
          }
        })
        .catch((ex)=>{
          reject(ex)
        })
      })
    },
  },
  mutations: {
    [SET_USER](state,data){ state.user=data ?? {} },
    [SET_TOKEN](state,data){ state.token=data ?? '' },
    [SET_BALANCE](state,data){ state.gl_balance = "132987" },
  },
}