export const Helpers = {
  rupeesToPaisa(rupees){
    return rupees * 100;    
  },
  paisaTorupees(paisa){
    return paisa / 100;    
  },
  generateInitials(string){
    const names = string.split(" ");
    let firstName = names[0];
    let lastName = names[names.length - 1];
    return firstName[0] + lastName[0];
  },
  verifyInputLength(event, maxLength) {
    const input = event.target;
    let value = input.value;

    // Check if the length exceeds the maximum length
    if (value.length > maxLength) {
      // Truncate the input value to the maximum length
      value = value.slice(0, maxLength);
      // Update the input value
      input.value = value;
    }

  },
  prepareLabels(str){
    if(str){
      return str.replace('_',' ').toUpperCase();
    }

    return str;
  },  
  formatNumber(number)  {
    if(number > 0){
      return Intl.NumberFormat().format(number);
    }
    return number;
  },
  
  isNumber: function(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
      evt.preventDefault();;
    } else {
      return true;
    }
  },
  validateFileMaxSize: function(file,size){
    const maxSizeInBytes = size * 1024 * 1024;
    if(file.size > maxSizeInBytes){
      return false;
    }
    return true;
  },
  checkFileTypeIsImage: function(file){
    if(!file.type.startsWith('image/')) {
      return false;
    }
    return true;
  },
  checkValidImageType: function(file){
    if(file.type.startsWith('image/')) {
      const allowedExtensions = ['jpg', 'jpeg', 'png', 'gif'];
      const fileExtension = file.name.split('.').pop().toLowerCase();

      // Check if the file extension is allowed
      if (!allowedExtensions.includes(fileExtension)) {
        return false;
      }

        return true;

    }
    else{
      return false;
    }

  },
  handlePaste:  function (event) {

  // Access the clipboard data
  const pastedData = (event.clipboardData || window.clipboardData).getData('text');

  // Validate if the pasted content is an integer
  if (!isValidInteger(pastedData)) {
    event.preventDefault();
  }
},
  isValidInteger: function (value) {
  // Use a regular expression to check if the value is an integer
  return /^\d+$/.test(value);
},
disablePreviousDate: function () {
  const today = new Date();
  const year = today.getFullYear();
  const month = (today.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
  const day = today.getDate().toString().padStart(2, '0');
  const currentDate = `${year}-${month}-${day}`;

  // Set the minimum allowed date for the input
  document.getElementById('dateInput').min = currentDate;
}

}