<template>
  <!-- Login Section -->
  <section class="login-section">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6 ">
          <div class="login-holder"> 
            <div class="login-block">
              <div class="login-col">
                <strong class="logo">
                  <img width="150" src="../../assets/images/logo-svg.svg"/>
                </strong>
                <h2>Log in to your Account</h2>
                <span class="title">Please sign in to access your data</span> 
                <div
                    class="error-message"
                    v-if="api.login.error_message"
                >
                  <p class="text-danger" style="font-size:11px !important;margin-bottom: 5px;">{{ api.login.error_message }}</p>
                </div> 
                <div v-if="screen==1"> 
                  <!-- form group -->
                  <div class="form-group">
                    <label class="label block-label"
                        for="email"
                    > 
                      Email
                    </label>
                    <input 
                      id="email" 
                      name="email" 
                      @keyup.enter="login()"
                      :disabled="isDisabled"
                      type="email" 
                      v-model="forms.login.username" 
                      class="form-control" 
                      autocomplete="off" 
                      placeholder="Enter you email" 
                      required 
                    />

                    <span class="text-danger" v-if="validations.username">{{validations.username[0]}}</span>

                  </div>

                  <div class="form-group">
                    <label 
                      class="label block-label"
                      for="password"
                    >
                      Password
                    </label>
                    <input
                        id="password"
                        name="password"
                        type="password"
                        @keyup.enter="login()"
                        v-model="forms.login.password"
                        class="form-control"
                        autocomplete="off"
                        placeholder="Enter Password"
                        required
                    />

                    <span class="text-danger" v-if="validations.password">{{validations.password[0]}}</span>

                  </div>
                  <!-- form group -->
                </div> 

                <div v-if="screen==2 && countdown>0">
                  <!-- form group -->
                  <div class="form-group">
                    <label 
                      class="label block-label"
                      for="email"
                    >
                      OTP
                    </label>
                    <div class="inputfield">
                      <input 
                        autofocus 
                        @input="changeNumberOtpPostion('otp_second')" 
                        id="otp_first" 
                        v-model="otp_first" 
                        type="number" 
                        maxlength="1" 
                        class="input"  
                      />
                      <input 
                        @keyup.delete="changeNumberOtpPostionBackspace('otp_first')" 
                        @input="changeNumberOtpPostion('otp_third')" 
                        v-model="otp_second" 
                        id="otp_second" 
                        type="number" 
                        maxlength="1" 
                        class="input"  
                      />
                      <input 
                        @keyup.delete="changeNumberOtpPostionBackspace('otp_second')" 
                        @input="changeNumberOtpPostion('otp_fourth')" 
                        v-model="otp_third" 
                        id="otp_third" 
                        type="number" 
                        maxlength="1" 
                        class="input"  
                      />
                      <input 
                        @keyup.delete="changeNumberOtpPostionBackspace('otp_third')" 
                        v-model="otp_fourth" 
                        id="otp_fourth" 
                        type="number" 
                        maxlength="1" 
                        class="input"  
                      />
                    </div>
                  </div>
                  <div class="form-group">
                    <span className="expiry-time d-block">
                      OTP Expires in {{ formatTime }}
                    </span>
                  </div>
                  <!-- form group -->
                </div>

                <div v-if="screen==2 && countdown==0">
                  <div class="row">
                    <div class="col-2"></div>
                    <div class="col-8">
                      <button class="fa fa-refresh  btn btn-primary" @click="resetOTP()">Resend OTP</button> 
                    </div>
                    <div class="col-2"></div> 
                  </div>
                  <!-- form group -->
                </div>

                <!-- sign in -->
                <div class="mb-0" v-if="countdown>0">
                  <button 
                    @click="login()" 
                    :disabled='isDisabled' 
                    class="btn btn-primary active mb-3"
                    >
                      Login
                  </button>
                  <RouterLink to="/forgot-password" class="back-to-login"> 
                     Forgot Password
                  </RouterLink>
                </div>  

                <div v-if="screen == 3">
                  <p class="text-primary" style="font-size:11px !important;margin-bottom: 5px;">Your Password has been Expired , Please set new Password</p>
                  <div class="form-group">
                    
                    <input id="email" name="email" type="hidden" v-model="formData.email" class="form-control" />
                  </div>

                  <div class="form-group">
                    <label for="current_password"><b>Current Password</b><em>*</em></label>
                    <input id="current_password" name="current_password" type="password" v-model="formData.current_password" class="form-control" placeholder="*********" autocomplete="new-password" required />
                    <span class="text-danger" v-if="validations?.current_password">{{validations?.current_password[0]}}</span>
                 
                  </div>
                 
                  <div class="form-group">
                  
                    <label for="password"> <b> New Password</b> <em>*</em></label>
                    <input id="password" name="password" type="password" v-model="formData.password" class="form-control" placeholder="*********" autocomplete="off" required />

                    <span class="text-danger" v-if="validations?.password">{{validations?.password[0]}}</span>

                  </div>
                  <div class="form-group">
                    <label for="new_confirm_password"> <b>Confirm New Password </b> <em>*</em></label>
                    <input id="new_confirm_password" name="new_confirm_password" type="password" v-model="formData.new_confirm_password" class="form-control" placeholder="*********" autocomplete="off" required />

                    <span class="text-danger" v-if="validations?.new_confirm_password">{{validations?.new_confirm_password[0]}}</span>

                  </div>
                  <div class="mb-0">
                    <button @click="setPassword()" :disabled="isDisabled" class="btn btn-primary active">Set New Password</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col col-md-6 col-block">  
          <div class="login-side-sec">
            <div class="login-bg"> 
            </div>
          </div> 
        </div> 
      </div> 
    </div>
     
  </section>
</template>

<script>
import { route_names } from '@/router'
import { LOGIN } from '@/store/actions'
import { mapActions } from 'vuex'
import {API,STORAGE} from "@/services";
import {Helpers} from "@/services/helpers.service";
import Swal from 'sweetalert2';

export default {

  name: 'LoginPage',


data(){
  return {
    loader: false,
    validations: {},
    countdown: 300000,

    currentPasswordError: '',
    invalidPasswordError: '',
    passwordError: '',


    forms: {
      login: {
        username: '',
        password: '',
        submitted: false,
      },
    },
    formData: {
        email: '',
        current_password: '',
        password: '',
        new_confirm_password: '',
       
      },
    isDisabled: null,
    currentDate: '',
    currentTime:'',
    screen:1,
    otp:"",
    otp_first:"",
    otp_second:"",
    otp_third:"",
    otp_fourth:"",
    api: {
      login: { 
        send: false,
        error_message: '', 
        validation_errors: {}, 
      },
    },
  }
},
  computed: {
    Helpers() {
      return Helpers
    },
    formatTime() {
      const minutes = Math.floor(this.countdown / 60000);
      const seconds = ((this.countdown % 60000) / 1000).toFixed(0);
      // Add leading zeros if needed
      const formattedMinutes = String(minutes).padStart(2, '0');
      const formattedSeconds = String(seconds).padStart(2, '0');
      return `${formattedMinutes}:${formattedSeconds}`;
    },
  },
methods: {
  restrictInput(event){
    console.log(event.target.id);
   let val=event.target.id;
   let vm=this;
    Helpers.verifyInputLength(event,4);
    this.otp= event.target.value;
  },
  startCountdown() {
    this.countdownInterval = setInterval(() => {
      if (this.countdown > 0) {
        this.countdown -= 1000; // Subtract 1 second (1000 milliseconds)
      } else {
        clearInterval(this.countdownInterval); // Stop the countdown when it reaches zero
      }
    }, 1000); // Update every 1 second (1000 milliseconds)
  },
  resetOTP(){
    this.otp_first="";
    this.otp_second="";
    this.otp_third="";
    this.otp_fourth="";
    this.otp=""
    this.api.login.error_message=""
    API.endpoints.auth.prelogin({
      username: this.forms.login.username,
      password: this.forms.login.password,
    }).then(
        (response) => {
          this.loader  = false;
          this.countdown=300000;
          this.screen=2;
          this.startCountdown();

        }
    ).catch((ex) => {
      vm.loader  = false;
      vm.isDisabled=false
      vm.api.login.error_message = ex?.response?.data?.message;
    })
    return;
  },
  callFunction: function () {
    let date = new Date();
    const options = { weekday: 'long',month: 'short', day: 'numeric', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);
    this.currentDate = formattedDate;
  },
  ...mapActions([
    LOGIN,
  ]),
  // forgetPassword(){
  
  // this.$router.push({
  //     name: route_names.forgot_password,
  //   })
  // },
  login() {
    let vm = this;
    vm.isDisabled = true;
    vm.validations =  {};
    
    vm.api.login.error_message = "";
    
    if(!vm.forms.login.username){
      vm.validations['username']        = ['The username field is required.'];
    }

    if(!vm.forms.login.password){
      vm.validations['password']        = ['The password field is required.'];
    }

    if(Object.keys(vm.validations).length!==0){
      vm.isDisabled=false;
      return false;
    }

    vm.loader  = true;

    if(vm.screen==1){
      API.endpoints.auth.prelogin({
        username: vm.forms.login.username,
        password: vm.forms.login.password,
      }).then(
        (response) => {

            vm.loader  = false;
            vm.isDisabled=false;

          if(response?.data?.data?.code == 213){
   
            vm.countdown=0;
            vm.screen=3;
            vm.formData.email= response?.data?.data?.email ;
          }
          else if(response?.data?.data?.code == 212){
            vm.startCountdown();
            vm.screen = 2
            vm.api.login.error_message = response?.data?.data?.message;
          }
          else{
            vm.startCountdown();
            vm.screen = 2;
          }
        }
      ).catch((ex) => {

        vm.loader  = false;
        vm.isDisabled=false

        if (!ex.response) {
          Swal.fire("Oops", "There was a problem connecting to the server. Please check your internet connection.", "warning");
        }
        else if (ex.response?.status == 422) {
          vm.api.login.error_message = ex?.response?.data?.message;
        }
        else {
          Swal.fire("Oops", ex?.response?.data?.message || "An error occurred while processing your request.", "warning");
        }
      })
      return;
    }

    if (vm.screen==2){
      if(vm.api.login.send) return;

      vm.api.login.send = true
      vm.api.login.error_message  = ''
      vm.api.login.validation_errors  = {}
      
      vm.otp  = vm.otp_first.toString()+vm.otp_second.toString()+vm.otp_third.toString()+vm.otp_fourth.toString()
      
      vm.LOGIN({
        username: vm.forms.login.username,
        password: vm.forms.login.password,
        otp: vm.otp,
      })
      .catch((ex)=>{

        vm.api.login.error_message=ex?.own_message
        vm.api.login.validation_errors  = ex?.own_errors
      })
      .finally(()=>{
        vm.loader  = false;
        vm.isDisabled = false;
        vm.api.login.send=false
      });
    }   
  },
  setPassword() {
      
    this.validations =  {};
    if (this.formData.password !== this.formData.new_confirm_password) {
      this.validations['new_confirm_password'] = ['New password and confirm password do not match.'];
      return;
    }

    this.loader = true;
    this.isDisabled = true;
    this.isDisabled = true;

    API.endpoints.auth.updateuserpass(this.formData)
    .then((response) => {

      this.validations =  {};
      Swal.fire('Success!', 'New password updated successfully Please Login.', 'success');

      this.loader = false;
      this.isDisabled = false;
      this.screen = 1;
      this.countdown = 30000;
      
      this.invalidPasswordError = "" ;
      this.currentPasswordError = "" ;
      this.otp_first = "";
      this.otp_second = "";
      this.otp_third = "";
      this.otp_fourth = "";
      this.otp = "";
      this.email = '' ;
      this.username = "";

    })
    .catch((ex) => {
      this.loader = false;
      this.isDisabled = false;

      if (!ex.response) {
          Swal.fire("Oops", "There was a problem connecting to the server. Please check your internet connection.", "warning");
        }
        else if (ex.response?.status == 422) {
          this.validations = ex?.response?.data?.errors;
        }
        else {
          Swal.fire("Oops", ex?.response?.data?.message || "An error occurred while processing your request.", "warning");
        }
        
    });
  },
  changeNumberOtpPostion(value){
    if(this.otp_first.toString().length>1){
      this.otp_first=this.otp_first.toString().slice(0,-1);
    }
    if(this.otp_second.toString().length>1){
      this.otp_second=this.otp_second.toString().slice(0,-1);
    }
    if(this.otp_third.toString().length>1){
      this.otp_third=this.otp_third.toString().slice(0,-1);
    }
    if(this.otp_fourth.toString().length>1){
      this.otp_fourth=this.otp_fourth.toString().slice(0,-1);
    }
    let val = document.getElementById(value);
    val.focus();
  },
  changeNumberOtpPostionBackspace(value){
    let val = document.getElementById(value);
    val.focus();
  }
},
mounted () { 
  

    this.callFunction(),
    setInterval(() => {
      let date = new Date();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const meridiem = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12;
      const timeStr = formattedHours + " : " + (minutes < 10 ? "0" : "") + minutes + " " + meridiem;
      this.currentTime = timeStr
    }, 1000)
  },
  watch:{
    otp_fourth:function(){
      if(this.otp_fourth.toString().length>1){
        this.otp_fourth=this.otp_fourth.toString().slice(0,-1);
      }
    }
  }
}
</script>