<template>
  <div>
    <div class="pm-employee-view-main">
        <div v-if="respnseLoader" class="response-loader" style="--n: 5">
            <div class="response-loader-inner">
                <div class="dot" style="--i: 0"></div>
                <div class="dot" style="--i: 1"></div>
                <div class="dot" style="--i: 2"></div>
                <div class="dot" style="--i: 3"></div>
                <div class="dot" style="--i: 4"></div>
            </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="row mb-3 ">
                <div class="col align-right">
                    <button class="btn btn-primary active" @click="addBankDetails()" v-if="!isHide">Add Bank Details</button>
                </div>
            </div>
              <div class="bank-details-outer loop-wrapper" v-for="(value,key) in items" :key="key">
                
                <div class="pm-employee-detail-head clearfix"> 
                  <span class="title"> Bank Account Detail {{ key + 1 }}  </span>
                  <span class=" m-2 badge " :class="value.status=='inactive' ? 'badge-danger' : 'badge-success'">{{value.status=='inactive' ? 'Inactive' : 'Active'}}</span>
                  <span class=" m-2 badge " :class="value?.is_verified == 1 ? 'badge-warning' : 'badge-danger'"> {{value?.is_verified == 1 ? 'Verified' : 'Not-Verified'}}</span>
                  <span class="float-end" v-if="items.length && !isHide" @click="updateBankDetail(value)">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd" d="M3.25 22C3.25 21.5858 3.58579 21.25 4 21.25H20C20.4142 21.25 20.75 21.5858 20.75 22C20.75 22.4142 20.4142 22.75 20 22.75H4C3.58579 22.75 3.25 22.4142 3.25 22Z" fill="#1C274C"/>
                        <path opacity="0.5" d="M19.0807 7.37162C20.3095 6.14279 20.3095 4.15046 19.0807 2.92162C17.8519 1.69279 15.8595 1.69279 14.6307 2.92162L13.9209 3.63141C13.9306 3.66076 13.9407 3.69052 13.9512 3.72066C14.2113 4.47054 14.7022 5.45356 15.6256 6.37698C16.549 7.30039 17.532 7.79126 18.2819 8.05142C18.3119 8.06183 18.3415 8.07187 18.3708 8.08155L19.0807 7.37162Z" fill="#1C274C"/>
                        <path d="M13.9511 3.59961L13.9205 3.63017C13.9303 3.65952 13.9403 3.68928 13.9508 3.71942C14.211 4.4693 14.7018 5.45232 15.6252 6.37574C16.5487 7.29915 17.5317 7.79002 18.2816 8.05018C18.3113 8.0605 18.3407 8.07046 18.3696 8.08005L11.5198 14.9299C11.058 15.3917 10.827 15.6227 10.5724 15.8213C10.2721 16.0555 9.94711 16.2564 9.60326 16.4202C9.31177 16.5591 9.00196 16.6624 8.38235 16.869L5.11497 17.9581C4.81005 18.0597 4.47388 17.9804 4.24661 17.7531C4.01934 17.5258 3.93998 17.1897 4.04162 16.8847L5.13074 13.6173C5.33728 12.9977 5.44055 12.6879 5.57947 12.3964C5.74334 12.0526 5.94418 11.7276 6.17844 11.4273C6.37702 11.1727 6.60794 10.9418 7.06971 10.48L13.9511 3.59961Z" fill="#1C274C"/>
                    </svg>


                  </span>
                </div>
                <div class="pm-employee-detail-box mb-3">
                  <div class="row mb-2">
                    <div class="col-md-4"> <strong class="title"> Bank Name: </strong> </div>
                    <div class="col-md-8"> <p> {{ value?.bank?.name ?? 'N/A' }} </p> </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-md-4"> <strong class="title"> Account Type: </strong> </div>
                    <div class="col-md-8"> <p> {{ value?.account_type ?? 'N/A' }} </p> </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-md-4"> <strong class="title"> Account Number: </strong> </div>
                    <div class="col-md-8"> <p> {{ value?.account_number ?? 'N/A' }} </p> </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-md-4"> <strong class="title"> Account Title: </strong> </div>
                    <div class="col-md-8"> <p> {{ value?.account_tile ?? 'N/A' }} </p> </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
    <updateBankDetails ref="UpdateBankDetails" />
    <addBankDetails ref="AddBankDetails" /> 
</template>
  
<script>

import { API } from '@/services';
import updateBankDetails from '../modals/UpdateBankDetails'
import addBankDetails from '../modals/AddBankDetails'

export default {
  
  props : ["isHide"],

  name: 'BankAccountDetails',
  components: {
    updateBankDetails,
    addBankDetails
  },
  data() {
    return {
      loader : false,
      items: {},
      employeeId: this.$parent.employeeId,
      respnseLoader: false
    }
  },
  methods : {
    getUserBankDetails(){
        let vm = this;
        this.respnseLoader=true
      let farmerBankDetailPayLoad = {
        employee_id : vm.employeeId,
      //  type:'farmer'

      }
        API.endpoints.user.userBankDetails(vm.employeeId,farmerBankDetailPayLoad)
        .then((data)=>{
          vm.items = data?.data?.data ?? null
          console.log('bank Details')
          console.log(vm.items)
        })
        .catch((ex)=>{ 
          reject(ex)
        }).finally(()=>{
          vm.respnseLoader=false
      })
    },
    updateBankDetail(value){
      console.log('bank value')
      console.log(value)

      this.$refs.UpdateBankDetails.form = {
        bank_id         : value.bank_id,
        account_number  : value.account_number,
        account_title   : value.account_tile,
        account_type    : value.account_type,
        status          : value.status,
        is_verified     : value.is_verified
      }
      this.$refs.UpdateBankDetails.employeeID = value.id
      this.$refs.UpdateBankDetails.modal.show()
    },
    addBankDetails(){
      this.$refs.AddBankDetails.employeeID = this.$parent.employeeId
      this.$refs.AddBankDetails.modal.show()
    }
  },
  created() {
    // this.getUserBankDetails()
  }
}
</script>