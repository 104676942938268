<template>
  <router-view />
</template>

<script>
import { mapMutations } from 'vuex'
import { STORAGE,API } from './services'
import { SET_TOKEN, SET_USER,SET_BALANCE } from './store/mutations'

export default {
  name: 'App',
  methods: {
    ...mapMutations([
      SET_TOKEN,
      SET_USER,
      SET_BALANCE,
    ]),
    disablePreviousDate: function () {
      const today = new Date();
      const year = today.getFullYear();
      const month = (today.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
      const day = today.getDate().toString().padStart(2, '0');
      const currentDate = `${year}-${month}-${day}`;

      // Set the minimum allowed date for the input
      document.getElementById('dateInput').min = currentDate;
    },

  },
  created(){
    this.SET_TOKEN(STORAGE.TOKEN.get() ?? '')
    this.SET_USER(STORAGE.USER.get() ?? {})
    API.init();

  }
}

</script>

<style>

</style>
<!-- <style lang="scss">
@import '@/assets/sass/app.scss'; 
</style>
 -->